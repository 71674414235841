import { useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 420,
      lgMobile: 600,
      tablet: 768,
      laptop: 992,
      desktop: 1200,
      dataGrid: 1040,
    },
  },
});

export const useMobileLayout = () => {
  return useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px)`);
};

export const useLargeMobileLayout = () => {
  return useMediaQuery(`(max-width: ${theme.breakpoints.values.lgMobile}px)`);
};

export const useTabletLayout = () => {
  return useMediaQuery(`(max-width: ${theme.breakpoints.values.tablet}px)`);
};

export const useLaptopLayout = () => {
  return useMediaQuery(`(max-width: ${theme.breakpoints.values.laptop}px)`);
};

export const useDesktopLayout = () => {
  return useMediaQuery(`(max-width: ${theme.breakpoints.values.desktop}px)`);
};

export const useCustomLayout = (breakpoint = 0) => {
  return useMediaQuery(`(max-width: ${breakpoint}px)`);
};

export const useDataGridLayout = () => {
  return useMediaQuery(`(max-width: ${theme.breakpoints.values.dataGrid}px)`);
};
