import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { differenceInDays } from "date-fns";

const EmployeeDetailsTable = ({
  rows = [],
  isLoading = false,
  paginationModel,
  onPaginationModelChange,
  rowCount,
}) => {
  const getTenureStatus = (hiredAt) => {
    if (!hiredAt) return "N/A";
    const daysSinceHire = differenceInDays(new Date(), new Date(hiredAt));
    return daysSinceHire > 90 ? ">90 days" : "<90 days";
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) => {
        const { first_name, last_name } = params.row.profile;
        return `${first_name} ${last_name}`;
      },
    },
    {
      field: "tenure",
      headerName: "Tenure",
      flex: 1,
      valueGetter: (params) => getTenureStatus(params.row.profile.hired_at),
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1,
      valueGetter: (params) => params.row.profile.office_location || "N/A",
    },
    {
      field: "supervisor",
      headerName: "Supervisor",
      flex: 1,
      valueGetter: (params) => params.row.profile.supervisor || "N/A",
    },
    {
      field: "sentiment_score",
      headerName: "Sentiment Score",
      flex: 1,
      valueGetter: (params) => {
        const score = params.row.survey?.["Sentiment Score"];
        return typeof score === "number" ? score : "N/A";
      },
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows.map((row) => ({
          ...row,
          id: row.profile.employee_id,
        }))}
        columns={columns}
        loading={isLoading}
        disableSelectionOnClick
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowCount={rowCount}
        paginationMode="server"
      />
    </div>
  );
};

export default EmployeeDetailsTable;
