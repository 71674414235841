import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "subframe/components/PageHeader";
import * as SubframeCore from "@subframe/core";
import { Tooltip } from "@mui/material";
import { Select } from "subframe";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { TextField } from "subframe";
import { Button } from "subframe";

import HeadcountGrid from "../HeadcountGrid";
import HeadcountTargetGrid from "../HeadcountTargetGrid";
import HeadcountForecastGrid from "../HeadcountForecastGrid";
import HeadcountForecastTargetGrid from "../HeadcountForecastTargetGrid";
import ADCStatsTable from "../ADCStatsTable";
import EmployeeDetailsModal from "../EmployeeDetailsModal";
import {
  fetchForecastedLaborData,
  fetchLaborData,
  fetchOfficeLocations,
  fetchRoster,
  fetchADCStats,
  fetchNewEmployeeWeight,
  updateNewEmployeeWeight,
} from "services/retention/employees";
import {
  fetchJobs,
  fetchServiceLines,
  fetchJobRatios,
} from "modules/Retention/services/retention";
import { ERROR_CODE } from "constants/constants";
import { useClickOutside } from "../../hooks/useClickOutside";
import { get as getUserRole } from "utils/localStorage";
import { EMPLOYEE_ROLES, AGENCY_ROLE } from "constants/constants";

const MultiSelectWithCheckbox = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  iconName,
  helperText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleToggleOption = (option, event) => {
    event.stopPropagation();
    let newValue;
    if (option === "All") {
      if (value.includes("All")) {
        newValue = [];
      } else {
        newValue = ["All"];
      }
    } else {
      if (value.includes("All")) {
        newValue = [option];
      } else {
        if (value.includes(option)) {
          newValue = value.filter((v) => v !== option);
          if (newValue.length === 0) newValue = ["All"];
        } else {
          newValue = [...value, option];
        }
      }
    }
    onChange(newValue);
  };

  const handleRemoveOption = (option, event) => {
    event.stopPropagation();
    handleToggleOption(option, event);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex flex-col gap-2">
        {/* Label */}
        <div className="flex items-center gap-2">
          <SubframeCore.Icon
            name={iconName}
            size={14}
            className="text-gray-400"
          />
          <label className="text-sm font-medium text-gray-700">{label}</label>
        </div>

        {/* Dropdown Container */}
        <div className="relative">
          {/* Dropdown Button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="w-full px-3 py-2 text-left border border-gray-300 rounded bg-white flex items-center justify-between max-h-[32px]"
          >
            <span className="text-sm text-gray-500">
              {value.includes("All") ? "All Selected" : placeholder}
            </span>
            <SubframeCore.Icon
              name={isOpen ? "FeatherChevronUp" : "FeatherChevronDown"}
              size={16}
              className="text-gray-400"
            />
          </button>

          {/* Dropdown Menu */}
          {isOpen && (
            <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto z-50">
              <div className="py-1">
                {options.map((option) => (
                  <div
                    key={option}
                    className="px-3 py-2 hover:bg-gray-50 cursor-pointer flex items-center gap-2"
                    onClick={(e) => handleToggleOption(option, e)}
                  >
                    <div className="w-4 h-4 border border-gray-300 rounded flex items-center justify-center">
                      {(value.includes(option) ||
                        (option !== "All" && value.includes("All"))) && (
                        <SubframeCore.Icon
                          name="FeatherCheck"
                          size={12}
                          className="text-brand-600"
                        />
                      )}
                    </div>
                    <span className="text-sm text-gray-700">
                      {option === "All" ? "Select All" : option}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Helper Text */}
        <p className="text-xs text-gray-500">{helperText}</p>

        {/* Selected Options Display - Moved after helper text */}
        {value.length > 0 && value[0] !== "All" && (
          <div className="flex flex-wrap gap-2">
            {value.map((option) => (
              <div
                key={option}
                className="bg-gray-100 text-gray-700 text-sm px-2 py-1 rounded-md flex items-center gap-1"
              >
                <span>{option}</span>
                <button
                  onClick={(e) => handleRemoveOption(option, e)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <SubframeCore.Icon name="FeatherX" size={12} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default function LaborReportData() {
  const navigate = useNavigate();
  const headcountReportRef = useRef(null);
  const forecastReportRef = useRef(null);

  // Error handling states
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);

  // Get user role and check if user is admin or agency
  const userRole = getUserRole("userRole");
  const hasLocationPermission =
    userRole === EMPLOYEE_ROLES.admin.value ||
    userRole === AGENCY_ROLE.agency.value;

  // State variables
  const [showHeadcountReport, setShowHeadcountReport] = useState(false);
  const [showForecastReport, setShowForecastReport] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [selectedJobs, setSelectedJobs] = useState(["All"]);
  const [isStaffingReportExpanded, setIsStaffingReportExpanded] =
    useState(true);
  const [showTargetInfo, setShowTargetInfo] = useState(false);
  const [showHeadcountInfo, setShowHeadcountInfo] = useState(false);
  const [showForecastInfo, setShowForecastInfo] = useState(false);
  const [showForecastDetailsInfo, setShowForecastDetailsInfo] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);

  // Service lines state
  const [serviceLines, setServiceLines] = useState([]);
  const [isLoadingServiceLines, setIsLoadingServiceLines] = useState(false);
  const [selectedServiceLine, setSelectedServiceLine] = useState(null);
  const [isServiceLineInitialized, setIsServiceLineInitialized] =
    useState(false);

  const [jobs, setJobs] = useState(["All"]);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);

  // State variables for both grids
  const [targetLaborData, setTargetLaborData] = useState([]);
  const [targetLaborRowCount, setTargetLaborRowCount] = useState(0);
  const [isTargetLaborLoading, setIsTargetLaborLoading] = useState(false);

  const [headcountLaborData, setHeadcountLaborData] = useState([]);
  const [headcountLaborRowCount, setHeadcountLaborRowCount] = useState(0);
  const [isHeadcountLaborLoading, setIsHeadcountLaborLoading] = useState(false);

  // Update state to store full job objects
  const [jobsData, setJobsData] = useState([]);

  // Add new state variables for forecasted data
  const [forecastedTargetData, setForecastedTargetData] = useState([]);
  const [forecastedTargetRowCount, setForecastedTargetRowCount] = useState(0);
  const [forecastedDetailsData, setForecastedDetailsData] = useState([]);
  const [forecastedDetailsRowCount, setForecastedDetailsRowCount] = useState(0);
  const [isForecastedTargetLoading, setIsForecastedTargetLoading] =
    useState(false);
  const [isForecastedDetailsLoading, setIsForecastedDetailsLoading] =
    useState(false);

  // Update locations state
  const [locations, setLocations] = useState(["All"]);
  const [selectedLocations, setSelectedLocations] = useState(["All"]);
  const [activeLocationTab, setActiveLocationTab] = useState("All");
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);

  // Employee Weight States
  const [newEmployeeWeight, setNewEmployeeWeight] = useState(null);
  const [isEditingWeight, setIsEditingWeight] = useState(false);
  const [weightInputValue, setWeightInputValue] = useState("");
  const [isLoadingWeight, setIsLoadingWeight] = useState(false);
  const [isUpdatingWeight, setIsUpdatingWeight] = useState(false);
  const [adcStats, setADCStats] = useState(null);
  const [isLoadingADC, setIsLoadingADC] = useState(false);

  // Pagination models
  const [targetPaginationModel, setTargetPaginationModel] = useState({
    page: 0,
    pageSize: 5,
    lastUpdated: Date.now(),
  });
  const [headcountPaginationModel, setHeadcountPaginationModel] = useState({
    page: 0,
    pageSize: 5,
    lastUpdated: Date.now(),
  });
  const [forecastPaginationModel, setForecastPaginationModel] = useState({
    page: 0,
    pageSize: 5,
    lastUpdated: Date.now(),
  });
  const [forecastTargetPaginationModel, setForecastTargetPaginationModel] =
    useState({
      page: 0,
      pageSize: 5,
      lastUpdated: Date.now(),
    });

  // Add cache state for each location's data
  const [locationDataCache, setLocationDataCache] = useState({});
  const [locationForecastCache, setLocationForecastCache] = useState({});
  const [locationADCCache, setLocationADCCache] = useState({});

  /**
   * Method to fetch service lines by API call.
   *
   * @returns {Promise}
   */
  const handleFetchServiceLines = async () => {
    setIsLoadingServiceLines(true);
    try {
      const [serviceResponse, ratiosResponse] = await Promise.all([
        fetchServiceLines(),
        fetchJobRatios()
      ]);

      if (serviceResponse?.success && ratiosResponse?.success) {
        const serviceLines = serviceResponse.result.service_lines;
        const jobRatios = ratiosResponse.result.job_ratios;
        setServiceLines(serviceLines);

        // Find first service line with ratio data
        if (serviceLines?.length > 0 && jobRatios?.length > 0) {
          const firstServiceLineWithRatios = serviceLines.find(service => 
            jobRatios.some(ratio => ratio.service_line_id === service.id)
          );

          // If found service line with ratios, set it, otherwise fall back to first service line
          const serviceLineToSelect = firstServiceLineWithRatios || serviceLines[0];
          setSelectedServiceLine(serviceLineToSelect);
          setIsServiceLineInitialized(true);
        }
      }
    } catch (error) {
      console.error("Error fetching service lines:", error);
    }
    setIsLoadingServiceLines(false);
  };

  // Add useEffect for fetching service lines
  useEffect(() => {
    handleFetchServiceLines();
  }, []);

  // Add useEffect for fetching locations
  useEffect(() => {
    handleFetchLocations();
  }, []);

  // Add useEffect for fetching ADC stats
  useEffect(() => {
    handleFetchADCStats();
  }, [activeLocationTab, selectedServiceLine]);

  // Effects for both grids
  useEffect(() => {
    if (isServiceLineInitialized) {
      handleFetchTargetLaborData(targetPaginationModel);
    }
  }, [
    isServiceLineInitialized,
    selectedServiceLine,
    activeLocationTab,
    searchQuery,
    targetPaginationModel.page,
    targetPaginationModel.pageSize,
    targetPaginationModel.lastUpdated,
    selectedJobs,
    jobsData,
  ]);

  useEffect(() => {
    if (isServiceLineInitialized && showHeadcountReport) {
      handleFetchHeadcountLaborData(headcountPaginationModel);
    }
  }, [
    isServiceLineInitialized,
    selectedServiceLine,
    activeLocationTab,
    searchQuery,
    headcountPaginationModel.page,
    headcountPaginationModel.pageSize,
    headcountPaginationModel.lastUpdated,
    showHeadcountReport,
    selectedJobs,
    jobsData,
  ]);

  // Add useEffect for fetching jobs
  useEffect(() => {
    handleFetchJobs();
  }, []);

  // Add useEffect for fetching forecasted data
  useEffect(() => {
    if (isServiceLineInitialized) {
      handleFetchForecastedTargetData(forecastTargetPaginationModel);
    }
  }, [
    isServiceLineInitialized,
    selectedServiceLine,
    activeLocationTab,
    searchQuery,
    forecastTargetPaginationModel.page,
    forecastTargetPaginationModel.pageSize,
    forecastTargetPaginationModel.lastUpdated,
    selectedJobs,
    jobsData,
  ]);

  useEffect(() => {
    if (isServiceLineInitialized && showForecastReport) {
      handleFetchForecastedDetailsData(forecastPaginationModel);
    }
  }, [
    isServiceLineInitialized,
    selectedServiceLine,
    activeLocationTab,
    searchQuery,
    forecastPaginationModel.page,
    forecastPaginationModel.pageSize,
    forecastPaginationModel.lastUpdated,
    showForecastReport,
    selectedJobs,
    jobsData,
  ]);

  // Add useEffect for fetching employee weight
  useEffect(() => {
    handleFetchNewEmployeeWeight();
  }, []);

  /**
   * Method to fetch locations by API call.
   *
   * @returns {Promise}
   */
  const handleFetchLocations = async () => {
    setIsLoadingLocations(true);
    try {
      const response = await fetchOfficeLocations();
      if (response?.success) {
        setLocations(["All", ...response.result.office_locations]);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
    setIsLoadingLocations(false);
  };

  /**
   * Method to fetch ADC stats by API call.
   *
   * @returns {Promise}
   */
  const handleFetchADCStats = async () => {
    setIsLoadingADC(true);
    try {
      const cacheKey = `${activeLocationTab}_${selectedServiceLine?.id}`;

      // Check if we have cached data
      if (locationADCCache[cacheKey]) {
        setADCStats(locationADCCache[cacheKey]);
        setIsLoadingADC(false);
        return;
      }

      const params = {
        office_location: activeLocationTab === "All" ? "" : activeLocationTab,
      };
      const response = await fetchADCStats(params);
      if (response?.success) {
        setADCStats(response.result.adc_stats);
        // Cache the ADC stats
        setLocationADCCache((prev) => ({
          ...prev,
          [cacheKey]: response.result.adc_stats,
        }));
      }
    } catch (error) {
      console.error("Error fetching ADC stats:", error);
    }
    setIsLoadingADC(false);
  };

  /**
   * Method to fetch jobs by API call.
   *
   * @returns {Promise}
   */
  const handleFetchJobs = async () => {
    setIsLoadingJobs(true);
    try {
      const response = await fetchJobs();
      if (response?.success) {
        setJobsData(response.result.jobs);
        const jobTitles = [
          "All",
          ...response.result.jobs.map((job) => job.job_title),
        ];
        setJobs(jobTitles);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
    setIsLoadingJobs(false);
  };

  // Handler functions
  const handleJobChange = (newValue) => {
    const newJobs = Array.isArray(newValue) ? newValue : [newValue];

    if (!selectedJobs.includes("All") && newJobs.includes("All")) {
      setSelectedJobs(["All"]);
      return;
    }

    if (selectedJobs.includes("All") && newJobs.length > 1) {
      const filteredJobs = newJobs.filter((job) => job !== "All");
      setSelectedJobs(filteredJobs);
      return;
    }

    if (newJobs.length === 0) {
      setSelectedJobs(["All"]);
      return;
    }

    setSelectedJobs(newJobs);
  };

  const handleShowHeadcountReport = () => {
    setShowHeadcountReport(true);
    setTimeout(() => {
      headcountReportRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 300);
  };

  const handleShowForecastReport = () => {
    setShowForecastReport(true);
    setTimeout(() => {
      forecastReportRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 300);
  };

  /**
   * Method to fetch labor data by API call.
   *
   * @param {Object} The filter object.
   * @returns {Promise}
   */
  const fetchLaborDataAsync = async (filter = {}) => {
    try {
      const cacheKey = `${activeLocationTab}_${
        selectedServiceLine?.id
      }_${searchQuery}_${filter.page}_${filter.pageSize}_${selectedJobs.join(
        ","
      )}`;

      // Check if we have cached data
      if (locationDataCache[cacheKey]) {
        return locationDataCache[cacheKey];
      }

      const params = {
        service_line_id: selectedServiceLine?.id,
        office_location: activeLocationTab === "All" ? "" : activeLocationTab,
        search: searchQuery,
        page: filter?.page + 1,
        limit: filter?.pageSize,
      };

      // Add job_ids only if specific jobs are selected and jobsData is available
      if (!selectedJobs.includes("All") && jobsData.length > 0) {
        const uniqueJobIds = [
          ...new Set(
            jobsData
              .filter((job) => selectedJobs.includes(job.job_title))
              .map((job) => job.id)
          ),
        ];
        params.job_ids = uniqueJobIds.join(",");
      }

      const response = await fetchLaborData(params);

      // Cache the response
      if (!response.hasError) {
        setLocationDataCache((prev) => ({
          ...prev,
          [cacheKey]: response,
        }));
      }

      return response;
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of target labor data.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchTargetLaborData = async (filter = {}) => {
    setIsTargetLaborLoading(true);
    const response = await fetchLaborDataAsync(filter);

    if (response?.hasError) {
      console.error("Error fetching target labor data:", response.error);
      setTargetLaborData([]);
      setTargetLaborRowCount(0);
    } else {
      setTargetLaborData(response?.result?.data || []);
      setTargetLaborRowCount(response?.result?.pagination?.total || 0);
    }

    setIsTargetLaborLoading(false);
  };

  /**
   * Handles the fetching of headcount labor data.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchHeadcountLaborData = async (filter = {}) => {
    setIsHeadcountLaborLoading(true);
    const response = await fetchLaborDataAsync(filter);

    if (response?.hasError) {
      console.error("Error fetching headcount labor data:", response.error);
      setHeadcountLaborData([]);
      setHeadcountLaborRowCount(0);
    } else {
      setHeadcountLaborData(response?.result?.data || []);
      setHeadcountLaborRowCount(response?.result?.pagination?.total || 0);
    }

    setIsHeadcountLaborLoading(false);
  };

  const handleJobCellClick = (jobTitle, jobId) => {
    setSelectedJob({ title: jobTitle, id: parseInt(jobId) });
    setIsEmployeeModalOpen(true);
  };

  const fetchEmployees = async ({ job_id, page, limit }) => {
    try {
      return await fetchRoster({
        job_id,
        page,
        limit,
      });
    } catch (error) {
      console.error("Error fetching employees:", error);
      return { success: false, result: { employees: [] } };
    }
  };

  /**
   * Method to fetch forecasted labor data by API call.
   *
   * @param {Object} The filter object.
   * @returns {Promise}
   */
  const fetchForecastedLaborDataAsync = async (filter = {}) => {
    try {
      const cacheKey = `${activeLocationTab}_${
        selectedServiceLine?.id
      }_${searchQuery}_${filter.page}_${filter.pageSize}_${selectedJobs.join(
        ","
      )}`;

      // Check if we have cached data
      if (locationForecastCache[cacheKey]) {
        return locationForecastCache[cacheKey];
      }

      const params = {
        service_line_id: selectedServiceLine?.id,
        office_location: activeLocationTab === "All" ? "" : activeLocationTab,
        search: searchQuery,
        page: filter?.page + 1,
        limit: filter?.pageSize,
      };

      // Add job_ids only if specific jobs are selected and jobsData is available
      if (!selectedJobs.includes("All") && jobsData.length > 0) {
        const uniqueJobIds = [
          ...new Set(
            jobsData
              .filter((job) => selectedJobs.includes(job.job_title))
              .map((job) => job.id)
          ),
        ];
        params.job_ids = uniqueJobIds.join(",");
      }

      const response = await fetchForecastedLaborData(params);

      // Cache the response
      if (!response.hasError) {
        setLocationForecastCache((prev) => ({
          ...prev,
          [cacheKey]: response,
        }));
      }

      return response;
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }
      return { hasError: true, error };
    }
  };

  /**
   * Handles the fetching of forecasted target data.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchForecastedTargetData = async (filter = {}) => {
    setIsForecastedTargetLoading(true);
    const response = await fetchForecastedLaborDataAsync(filter);

    if (response?.hasError) {
      console.error("Error fetching forecasted target data:", response.error);
      setForecastedTargetData([]);
      setForecastedTargetRowCount(0);
    } else {
      setForecastedTargetData(response?.result?.data || []);
      setForecastedTargetRowCount(response?.result?.pagination?.total || 0);
    }

    setIsForecastedTargetLoading(false);
  };

  /**
   * Handles the fetching of forecasted details data.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchForecastedDetailsData = async (filter = {}) => {
    setIsForecastedDetailsLoading(true);
    const response = await fetchForecastedLaborDataAsync(filter);

    if (response?.hasError) {
      console.error("Error fetching forecasted details data:", response.error);
      setForecastedDetailsData([]);
      setForecastedDetailsRowCount(0);
    } else {
      setForecastedDetailsData(response?.result?.data || []);
      setForecastedDetailsRowCount(response?.result?.pagination?.total || 0);
    }

    setIsForecastedDetailsLoading(false);
  };

  const fetchAllHeadcountData = async () => {
    try {
      const response = await fetchLaborDataAsync({
        office_id: selectedLocation?.id,
        service_line_id: selectedServiceLine?.id,
        job_id: selectedJob?.id,
      });
      return response?.result?.data || [];
    } catch (error) {
      console.error("Failed to fetch all headcount data:", error);
      throw error;
    }
  };

  const fetchAllTargetData = async () => {
    try {
      const response = await fetchLaborDataAsync({
        office_id: selectedLocation?.id,
        service_line_id: selectedServiceLine?.id,
        job_id: selectedJob?.id,
      });
      return response?.result?.data || [];
    } catch (error) {
      console.error("Failed to fetch all target data:", error);
      throw error;
    }
  };

  const fetchAllForecastData = async () => {
    try {
      const response = await fetchForecastedLaborDataAsync({
        office_id: selectedLocation?.id,
        service_line_id: selectedServiceLine?.id,
        job_id: selectedJob?.id,
      });
      return response?.result?.data || [];
    } catch (error) {
      console.error("Failed to fetch all forecast data:", error);
      throw error;
    }
  };

  const fetchAllForecastTargetData = async () => {
    try {
      const response = await fetchForecastedLaborDataAsync({
        office_id: selectedLocation?.id,
        service_line_id: selectedServiceLine?.id,
        job_id: selectedJob?.id,
      });
      return response?.result?.data || [];
    } catch (error) {
      console.error("Failed to fetch all forecast target data:", error);
      throw error;
    }
  };

  // Add employee weight handlers
  const handleFetchNewEmployeeWeight = async () => {
    setIsLoadingWeight(true);
    try {
      const response = await fetchNewEmployeeWeight();
      if (response?.success) {
        setNewEmployeeWeight(response.result.weight);
        setWeightInputValue((response.result.weight * 100).toFixed(0));
      } else {
        setErrorMessage("Failed to fetch new employee weight setting");
        setIsErrorMessageShown(true);
      }
    } catch (error) {
      console.error("Error fetching new employee weight:", error);
      setErrorMessage(`Error fetching new employee weight: ${error?.message}`);
      setIsErrorMessageShown(true);
    }
    setIsLoadingWeight(false);
  };

  const handleWeightChange = (value) => {
    // Remove any non-numeric characters except decimal point
    const filtered = value.replace(/[^\d.]/g, "");

    // Ensure only one decimal point
    const parts = filtered.split(".");
    const sanitized = parts[0] + (parts.length > 1 ? "." + parts[1] : "");

    // Convert to number and check if it's within range
    const numValue = parseFloat(sanitized);
    if (sanitized === "" || (numValue <= 100 && !isNaN(numValue))) {
      setWeightInputValue(sanitized);
    }
  };

  const handleWeightUpdate = async () => {
    const numValue = parseFloat(weightInputValue);
    if (isNaN(numValue)) {
      setWeightInputValue((newEmployeeWeight * 100).toFixed(0));
      return;
    }

    setIsUpdatingWeight(true);
    try {
      const response = await updateNewEmployeeWeight({
        weight: numValue / 100,
      });
      if (response?.success) {
        setNewEmployeeWeight(response.result.weight);
        setIsEditingWeight(false);

        // Clear all caches
        clearDataCache();

        // Update pagination models to trigger data refresh
        const timestamp = Date.now();
        setTargetPaginationModel((prev) => ({
          ...prev,
          lastUpdated: timestamp,
        }));
        setHeadcountPaginationModel((prev) => ({
          ...prev,
          lastUpdated: timestamp,
        }));
        setForecastPaginationModel((prev) => ({
          ...prev,
          lastUpdated: timestamp,
        }));
        setForecastTargetPaginationModel((prev) => ({
          ...prev,
          lastUpdated: timestamp,
        }));

        setSuccessMessage("Weight updated successfully");
        setIsSuccessMessageShown(true);
      } else {
        setWeightInputValue((newEmployeeWeight * 100).toFixed(0));
        setErrorMessage("Failed to update new employee weight setting");
        setIsErrorMessageShown(true);
      }
    } catch (error) {
      setWeightInputValue((newEmployeeWeight * 100).toFixed(0));
      console.error("Error updating new employee weight:", error);
      setErrorMessage(`Error updating new employee weight: ${error?.message}`);
      setIsErrorMessageShown(true);
    }
    setIsUpdatingWeight(false);
  };

  const startEditingWeight = () => {
    setWeightInputValue((newEmployeeWeight * 100).toFixed(0));
    setIsEditingWeight(true);
  };

  // Add location change handler
  const handleLocationChange = (newValue) => {
    const newLocations = Array.isArray(newValue) ? newValue : [newValue];

    if (!selectedLocations.includes("All") && newLocations.includes("All")) {
      setSelectedLocations(["All"]);
      setActiveLocationTab("All");
      return;
    }

    if (selectedLocations.includes("All") && newLocations.length > 1) {
      const filteredLocations = newLocations.filter((loc) => loc !== "All");
      setSelectedLocations(filteredLocations);
      setActiveLocationTab(filteredLocations[0]);
      return;
    }

    if (newLocations.length === 0) {
      setSelectedLocations(["All"]);
      setActiveLocationTab("All");
      return;
    }

    setSelectedLocations(newLocations);
    if (!newLocations.includes(activeLocationTab)) {
      setActiveLocationTab(newLocations[0]);
    }
  };

  // Add function to clear cache when filters change
  const clearDataCache = () => {
    setLocationDataCache({});
    setLocationForecastCache({});
    setLocationADCCache({});
  };

  // Clear cache when service line or jobs change
  useEffect(() => {
    clearDataCache();
  }, [selectedServiceLine?.id, selectedJobs.join(",")]);

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <div className="w-full flex flex-col gap-12">
        {/* Staffing Report Section */}
        <div className="w-full flex-col gap-6 items-start flex border border-gray-200 rounded-lg p-6">
          <div
            className="w-full flex items-center gap-4 cursor-pointer"
            onClick={() =>
              setIsStaffingReportExpanded(!isStaffingReportExpanded)
            }
          >
            <SubframeCore.Icon
              className="text-subtext-color text-section-header font-section-header"
              name={
                isStaffingReportExpanded
                  ? "FeatherChevronDown"
                  : "FeatherChevronRight"
              }
            />
            <span className="text-default-font text-section-header font-section-header">
              {selectedLocation === "All"
                ? "Staffing Report"
                : `Staffing Report | ${selectedLocation}`}
            </span>
          </div>

          {isStaffingReportExpanded && (
            <div className="w-full flex flex-col gap-12 pt-4">
              {/* Filters */}
              <div className="w-full flex-col gap-6 items-start flex">
                <PageHeader>
                  <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                    <SubframeCore.Icon
                      className="text-subtext-color text-section-header font-section-header"
                      name="FeatherFilter"
                    />
                    <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                      Filters & Search
                    </span>
                  </div>
                </PageHeader>
                <div className="w-full bg-white rounded-lg border border-gray-200 p-6">
                  <div className="w-full flex flex-col gap-6">
                    {/* Search Input */}
                    <div className="w-full">
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <SubframeCore.Icon
                            name="FeatherSearch"
                            size={16}
                            className="text-gray-400"
                          />
                        </div>
                        <input
                          type="text"
                          className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                          placeholder="Search by job title"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Divider */}
                    <div className="border-t border-gray-200"></div>

                    {/* Filter Controls */}
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
                      {/* Service Line Filter */}
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                          <SubframeCore.Icon
                            name="FeatherBriefcase"
                            size={14}
                            className="text-gray-400"
                          />
                          <label className="text-sm font-medium text-gray-700">
                            Service Line
                          </label>
                        </div>
                        <Select
                          className="w-full"
                          placeholder="Select Service Line"
                          value={selectedServiceLine?.name}
                          onValueChange={(name) => {
                            const service = serviceLines.find(
                              (s) => s.name === name
                            );
                            setSelectedServiceLine(service);
                          }}
                        >
                          {serviceLines.map((service) => (
                            <Select.Item key={service.id} value={service.name}>
                              {service.name}
                            </Select.Item>
                          ))}
                        </Select>
                        <p className="text-xs text-gray-500">
                          Select service line to apply ratios
                        </p>
                      </div>

                      {/* Branch Location Filter */}
                      {hasLocationPermission && (
                        <div className="flex flex-col gap-2">
                          <MultiSelectWithCheckbox
                            options={locations}
                            value={selectedLocations}
                            onChange={handleLocationChange}
                            placeholder="Select Branch Locations"
                            label="Branch Location"
                            iconName="FeatherMapPin"
                            helperText="Filter by branch location (multiple)"
                            disabled={isLoadingLocations}
                          />
                        </div>
                      )}

                      {/* Job Title Filter */}
                      <MultiSelectWithCheckbox
                        options={jobs}
                        value={selectedJobs}
                        onChange={handleJobChange}
                        placeholder="Select Job Titles"
                        label="Job Title"
                        iconName="FeatherUser"
                        helperText="Filter by employee job title (multiple)"
                        disabled={isLoadingJobs}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Location Tabs with Content */}
              {selectedLocations.length > 0 && (
                <div className="w-full">
                  {/* Tabs */}
                  <div className="border border-gray-200 rounded-lg bg-white">
                    <div className="overflow-x-auto">
                      <div className="flex min-w-[850px] border-b border-gray-200">
                        {selectedLocations.map((location) => (
                          <button
                            key={location}
                            className={`px-6 py-3 text-sm font-medium transition-colors duration-200 ${
                              activeLocationTab === location
                                ? "border-b-2 border-brand-600 text-brand-600 bg-brand-50"
                                : "text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                            }`}
                            onClick={() => setActiveLocationTab(location)}
                          >
                            <div className="flex items-center gap-2">
                              <SubframeCore.Icon
                                name="FeatherMapPin"
                                size={14}
                                className={
                                  activeLocationTab === location
                                    ? "text-brand-600"
                                    : "text-gray-400"
                                }
                              />
                              {location === "All" ? "All Branches" : location}
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Tab Content */}
                    <div className="p-6 border-t border-gray-200">
                      {/* ADC Stats Section */}
                      <div className="w-full flex-col gap-6 items-start flex">
                        <PageHeader>
                          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                            <SubframeCore.Icon
                              className="text-subtext-color text-section-header font-section-header"
                              name="FeatherActivity"
                            />
                            <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                              Average Daily Census
                            </span>
                          </div>
                        </PageHeader>
                        <ADCStatsTable
                          adcStats={adcStats}
                          isLoading={isLoadingADC}
                        />
                      </div>

                      {/* Target Analysis */}
                      <div className="w-full flex-col gap-6 items-start flex mt-8">
                        <PageHeader>
                          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                            <SubframeCore.Icon
                              className="text-subtext-color text-section-header font-section-header"
                              name="FeatherTarget"
                            />
                            <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                              Target Analysis
                            </span>
                            {!showHeadcountReport && (
                              <button
                                onClick={handleShowHeadcountReport}
                                className="flex items-center gap-2 text-brand-600 hover:text-brand-700"
                              >
                                <SubframeCore.Icon
                                  name="FeatherEye"
                                  size={16}
                                />
                                <span>Show Headcount Report</span>
                              </button>
                            )}
                          </div>
                        </PageHeader>
                        {/* Info Section for Target Analysis */}
                        <div className="w-full mb-4 bg-gray-50 rounded-lg p-4">
                          <div
                            className="flex items-center gap-2 cursor-pointer mb-2"
                            onClick={() => setShowTargetInfo(!showTargetInfo)}
                          >
                            <SubframeCore.Icon
                              name={
                                showTargetInfo
                                  ? "FeatherChevronDown"
                                  : "FeatherChevronRight"
                              }
                              size={20}
                            />
                            <span className="font-medium">
                              Understanding the Target Analysis
                            </span>
                          </div>
                          {showTargetInfo && (
                            <div className="pl-6 space-y-3">
                              <p className="text-sm text-gray-600">
                                This table shows the clinical job categories
                                within our clients organization.
                              </p>
                              <div className="space-y-2">
                                <h4 className="text-sm font-medium text-left">
                                  Key Formulas:
                                </h4>
                                <div className="bg-white p-3 rounded border border-gray-200">
                                  <div className="flex items-center gap-2">
                                    <SubframeCore.Icon
                                      name="FeatherTarget"
                                      size={16}
                                      className="text-brand-600"
                                    />
                                    <span className="text-sm">
                                      Target = ADC ÷ Ratio
                                    </span>
                                  </div>
                                  <div className="text-xs text-gray-500 mt-1">
                                    Example: RN Target = 100 ÷ 12.5
                                  </div>
                                </div>
                                <div className="bg-white p-3 rounded border border-gray-200">
                                  <div className="flex items-center gap-2">
                                    <div className="flex flex-col -space-y-2 pt-1">
                                      <SubframeCore.Icon
                                        name="FeatherPlus"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                      <SubframeCore.Icon
                                        name="FeatherMinus"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                    </div>
                                    <span className="text-sm">
                                      Over/Under = Normalized Headcount - Target
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="w-full h-[320px]">
                          <HeadcountTargetGrid
                            rows={targetLaborData}
                            isLoading={isTargetLaborLoading}
                            rowCount={targetLaborRowCount}
                            paginationModel={targetPaginationModel}
                            setPaginationModel={setTargetPaginationModel}
                            onViewDetails={handleShowHeadcountReport}
                            onJobClick={handleJobCellClick}
                            fetchAllData={fetchAllTargetData}
                          />
                        </div>
                      </div>

                      {/* Headcount Report */}
                      {showHeadcountReport && (
                        <div
                          ref={headcountReportRef}
                          className="w-full flex-col gap-6 items-start flex mt-8"
                        >
                          <PageHeader>
                            <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                              <SubframeCore.Icon
                                className="text-subtext-color text-section-header font-section-header"
                                name="FeatherUsers"
                              />
                              <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                                Headcount Report
                              </span>
                              <Tooltip title="Hide section">
                                <div
                                  onClick={() => setShowHeadcountReport(false)}
                                  className="cursor-pointer text-neutral-400 hover:text-neutral-500"
                                >
                                  <SubframeCore.Icon
                                    name="FeatherMinimize2"
                                    size={20}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </PageHeader>

                          {/* New Employee Weight Setting */}
                          <div className="w-full bg-white rounded-lg border border-gray-200 p-6">
                            <div className="flex items-center justify-between">
                              <div className="flex flex-col">
                                <span className="text-sm font-medium text-gray-700">
                                  New Employee Productivity Weight
                                </span>
                                <span className="text-xs text-gray-500">
                                  Set how productive new employees (&lt;90 days)
                                  are considered relative to experienced
                                  employees
                                </span>
                              </div>
                              <div className="flex items-center gap-2">
                                {isLoadingWeight ? (
                                  <div className="w-24 h-9 bg-gray-100 rounded animate-pulse" />
                                ) : isEditingWeight ? (
                                  <div className="flex items-center gap-2">
                                    <TextField className="w-20">
                                      <TextField.Input
                                        value={weightInputValue}
                                        onChange={(e) =>
                                          handleWeightChange(e.target.value)
                                        }
                                        placeholder="0"
                                        onFocus={(e) => e.target.select()}
                                      />
                                    </TextField>
                                    <span className="ml-[-25px] text-gray-500">
                                      %
                                    </span>
                                    <div className="flex gap-2">
                                      <Button
                                        variant="brand-secondary"
                                        onClick={() =>
                                          setIsEditingWeight(false)
                                        }
                                        disabled={isUpdatingWeight}
                                        className="whitespace-nowrap"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="brand-primary"
                                        onClick={handleWeightUpdate}
                                        loading={isUpdatingWeight}
                                        className="whitespace-nowrap"
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium">
                                      {newEmployeeWeight
                                        ? (newEmployeeWeight * 100).toFixed(0)
                                        : "0"}
                                      %
                                    </span>
                                    <button
                                      onClick={startEditingWeight}
                                      className="p-1 hover:bg-gray-100 rounded"
                                    >
                                      <SubframeCore.Icon
                                        name="FeatherEdit2"
                                        size={14}
                                        className="text-gray-500"
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Info Section for Headcount Report */}
                          <div className="w-full mb-4 bg-gray-50 rounded-lg p-4">
                            <div
                              className="flex items-center gap-2 cursor-pointer mb-2"
                              onClick={() =>
                                setShowHeadcountInfo(!showHeadcountInfo)
                              }
                            >
                              <SubframeCore.Icon
                                name={
                                  showHeadcountInfo
                                    ? "FeatherChevronDown"
                                    : "FeatherChevronRight"
                                }
                                size={20}
                              />
                              <span className="font-medium">
                                Understanding the Headcount Report
                              </span>
                            </div>
                            {showHeadcountInfo && (
                              <div className="pl-6 space-y-3">
                                <p className="text-sm text-gray-600">
                                  This table determines our clients normalized
                                  headcount by considering the total # of new
                                  hires (&lt;90 days) in their workforce, these
                                  hires are generally less productive than
                                  senior staff members.
                                </p>
                                <div className="space-y-2">
                                  <h4 className="text-sm font-medium text-left">
                                    Key Formulas:
                                  </h4>
                                  <div className="bg-white p-3 rounded border border-gray-200">
                                    <div className="flex items-center gap-2">
                                      <SubframeCore.Icon
                                        name="FeatherUsers"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                      <span className="text-sm">
                                        Normalized Headcount = (Employees on
                                        Payroll - Employees &lt;90 days) +
                                        (Employees &lt;90days ÷ 2)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="bg-white p-3 rounded border border-gray-200">
                                    <div className="flex items-center gap-2">
                                      <SubframeCore.Icon
                                        name="FeatherPercent"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                      <span className="text-sm">
                                        % of New Hires = (Employees &lt;90 Days
                                        ÷ Employees on Payroll) × 100
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full h-[320px]">
                            <HeadcountGrid
                              rows={headcountLaborData}
                              isLoading={isHeadcountLaborLoading}
                              rowCount={headcountLaborRowCount}
                              paginationModel={headcountPaginationModel}
                              setPaginationModel={setHeadcountPaginationModel}
                              onJobClick={handleJobCellClick}
                              fetchAllData={fetchAllHeadcountData}
                            />
                          </div>
                        </div>
                      )}

                      {/* Forecast Analysis */}
                      <div className="w-full flex-col gap-6 items-start flex mt-8">
                        <PageHeader>
                          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                            <SubframeCore.Icon
                              className="text-subtext-color text-section-header font-section-header"
                              name="FeatherTrendingUp"
                            />
                            <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                              Forecast Analysis
                            </span>
                            {!showForecastReport && (
                              <button
                                onClick={handleShowForecastReport}
                                className="flex items-center gap-2 text-brand-600 hover:text-brand-700"
                              >
                                <SubframeCore.Icon
                                  name="FeatherEye"
                                  size={16}
                                />
                                <span>Show Forecast Report</span>
                              </button>
                            )}
                          </div>
                        </PageHeader>
                        {/* Info Section for Forecast Analysis */}
                        <div className="w-full mb-4 bg-gray-50 rounded-lg p-4">
                          <div
                            className="flex items-center gap-2 cursor-pointer mb-2"
                            onClick={() =>
                              setShowForecastInfo(!showForecastInfo)
                            }
                          >
                            <SubframeCore.Icon
                              name={
                                showForecastInfo
                                  ? "FeatherChevronDown"
                                  : "FeatherChevronRight"
                              }
                              size={20}
                            />
                            <span className="font-medium">
                              Understanding the Forecast Analysis
                            </span>
                          </div>
                          {showForecastInfo && (
                            <div className="pl-6 space-y-3">
                              <p className="text-sm text-gray-600">
                                This table shows Levo's prediction for our
                                client's headcount in one month.
                              </p>
                              <div className="space-y-2">
                                <h4 className="text-sm font-medium text-left">
                                  Key Formulas:
                                </h4>
                                <div className="bg-white p-3 rounded border border-gray-200">
                                  <div className="flex items-center gap-2">
                                    <div className="flex flex-col -space-y-2 pt-1">
                                      <SubframeCore.Icon
                                        name="FeatherPlus"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                      <SubframeCore.Icon
                                        name="FeatherMinus"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                    </div>
                                    <span className="text-sm">
                                      Forecasted Over/Under = Forecasted
                                      Headcount - Target
                                    </span>
                                  </div>
                                </div>
                                <div className="bg-white p-3 rounded border border-gray-200">
                                  <div className="flex items-center gap-2">
                                    <SubframeCore.Icon
                                      name="FeatherActivity"
                                      size={16}
                                      className="text-brand-600"
                                    />
                                    <span className="text-sm">
                                      Change MoM = Forecasted Headcount -
                                      Normalized Headcount
                                    </span>
                                  </div>
                                  <div className="text-xs text-gray-500 mt-1">
                                    MoM = Month over Month
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="w-full h-[320px]">
                          <HeadcountForecastTargetGrid
                            rows={forecastedTargetData}
                            isLoading={isForecastedTargetLoading}
                            rowCount={forecastedTargetRowCount}
                            paginationModel={forecastTargetPaginationModel}
                            setPaginationModel={
                              setForecastTargetPaginationModel
                            }
                            onViewDetails={handleShowForecastReport}
                            onJobClick={handleJobCellClick}
                            fetchAllData={fetchAllForecastTargetData}
                          />
                        </div>
                      </div>

                      {/* Forecast Details */}
                      {showForecastReport && (
                        <div
                          ref={forecastReportRef}
                          className="w-full flex-col gap-6 items-start flex mt-8"
                        >
                          <PageHeader>
                            <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                              <SubframeCore.Icon
                                className="text-subtext-color text-section-header font-section-header"
                                name="FeatherActivity"
                              />
                              <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
                                Forecast Details
                              </span>
                              <Tooltip title="Hide section">
                                <div
                                  onClick={() => setShowForecastReport(false)}
                                  className="cursor-pointer text-neutral-400 hover:text-neutral-500"
                                >
                                  <SubframeCore.Icon
                                    name="FeatherMinimize2"
                                    size={20}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </PageHeader>
                          {/* Info Section for Forecast Details */}
                          <div className="w-full mb-4 bg-gray-50 rounded-lg p-4">
                            <div
                              className="flex items-center gap-2 cursor-pointer mb-2"
                              onClick={() =>
                                setShowForecastDetailsInfo(
                                  !showForecastDetailsInfo
                                )
                              }
                            >
                              <SubframeCore.Icon
                                name={
                                  showForecastDetailsInfo
                                    ? "FeatherChevronDown"
                                    : "FeatherChevronRight"
                                }
                                size={20}
                              />
                              <span className="font-medium">
                                Understanding the Forecast Details
                              </span>
                            </div>
                            {showForecastDetailsInfo && (
                              <div className="pl-6 space-y-3">
                                <p className="text-sm text-gray-600">
                                  This table shows how we arrive at our
                                  Forecasted Normalized Headcount.
                                </p>
                                <div className="space-y-2">
                                  <h4 className="text-sm font-medium text-left">
                                    Key Formulas:
                                  </h4>
                                  <div className="bg-white p-3 rounded border border-gray-200">
                                    <div className="flex items-center gap-2">
                                      <SubframeCore.Icon
                                        name="FeatherUsers"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                      <span className="text-sm">
                                        Forecasted Normalized Headcount =
                                        (Predicted Emp. on Payroll - Predicted
                                        Emp. &lt;90 Days) + (Predicted Emp.
                                        &lt;90 Days ÷ 2)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="bg-white p-3 rounded border border-gray-200">
                                    <div className="flex items-center gap-2">
                                      <SubframeCore.Icon
                                        name="FeatherActivity"
                                        size={16}
                                        className="text-brand-600"
                                      />
                                      <span className="text-sm">
                                        Predicted Emp. on Payroll = Current Emp.
                                        Payroll - Employees at risk
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="w-full h-[320px]">
                            <HeadcountForecastGrid
                              rows={forecastedDetailsData}
                              isLoading={isForecastedDetailsLoading}
                              rowCount={forecastedDetailsRowCount}
                              paginationModel={forecastPaginationModel}
                              setPaginationModel={setForecastPaginationModel}
                              onJobClick={handleJobCellClick}
                              fetchAllData={fetchAllForecastData}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <EmployeeDetailsModal
        open={isEmployeeModalOpen}
        onClose={() => setIsEmployeeModalOpen(false)}
        jobTitle={selectedJob?.title}
        jobId={selectedJob?.id}
        fetchEmployees={fetchEmployees}
      />
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
}
