import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDataGridLayout } from "hooks/mediaQueryHooks";

const getStyles = (isSmallScreen) => ({
  "& .MuiDataGrid-virtualScroller": {
    overflow: "auto !important",
    marginTop: "40px !important",
    height: "240px !important",
    ...(!isSmallScreen && {
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    }),
  },
});

const CustomDataGrid = styled(DataGrid)(({ theme, isSmallScreen }) => ({
  height: 320,
  width: "100%",
  "& .MuiDataGrid-main": {
    overflow: "hidden",
  },
  ...getStyles(isSmallScreen),
  "& .MuiDataGrid-virtualScrollerContent": {
    height: "auto !important",
    minHeight: "unset !important",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    transform: "none !important",
    position: "relative !important",
    maxHeight: "200px !important",
  },
  "& .MuiDataGrid-renderingZone": {
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "unset !important",
    maxHeight: "40px !important",
    minHeight: "40px !important",
    whiteSpace: "normal",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& .MuiDataGrid-row, & .MuiDataGrid-row--lastVisible": {
    maxHeight: "40px !important",
    minHeight: "40px !important",
    "& .MuiDataGrid-cell": {
      maxHeight: "40px !important",
      minHeight: "40px !important",
    },
  },
  "& .MuiDataGrid-columnHeaders": {
    minHeight: "40px !important",
    maxHeight: "40px !important",
    lineHeight: "40px !important",
    backgroundColor: "#f8fafc",
  },
  "& .MuiDataGrid-footerContainer": {
    minHeight: "40px !important",
    maxHeight: "40px !important",
    borderTop: "none"
  },
  "& .super-app-theme--header": {
    backgroundColor: "#f8fafc",
    fontWeight: "bold",
  },
  fontFamily: "Nunito",
  color: "#171717",
  overflow: "hidden",
  "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row.pinned": {
    backgroundColor: "#f8fafc",
    borderTop: "1px solid #e2e8f0",
    borderBottom: "1px solid #e2e8f0",
    fontWeight: "bold",
    position: "sticky",
    bottom: 0,
    "&:hover": {
      backgroundColor: "#f1f5f9",
    }
  },
  "& .MuiDataGrid-pinnedRows": {
    boxShadow: "0 -2px 4px rgba(0,0,0,0.1)",
    zIndex: 3
  }
}));

export default function StyledDataGrid({
  rows = [],
  rowCount,
  columns = [],
  paginationModel,
  isLoading = false,
  setPaginationModel,
  ...props
}) {
  const theme = useTheme();
  const isSmallScreen = useDataGridLayout();

  return (
    <div
      style={{
        height: 320,
        width: "100%",
        overflow: isSmallScreen ? "auto" : "hidden",
      }}
    >
      <CustomDataGrid
        rows={rows}
        rowCount={rowCount}
        loading={isLoading}
        rowsPerPageOptions={[5, 10]}
        pagination
        page={paginationModel?.page}
        pageSize={paginationModel?.pageSize}
        paginationMode="server"
        onPageChange={(newPage) =>
          setPaginationModel((old) => ({ ...old, page: newPage }))
        }
        onPageSizeChange={(newPageSize) =>
          setPaginationModel((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        disableSelectionOnClick
        autoHeight={false}
        hideFooterSelectedRowCount
        disableColumnMenu
        isSmallScreen={isSmallScreen}
        sx={{
          "& .MuiDataGrid-root": {
            width: "100%",
            maxWidth: isSmallScreen ? "none" : "1040px",
            margin: "0 auto",
          },
        }}
        getRowClassName={(params) =>
          params.id === "total" ? "pinned font-bold" : ""
        }
        {...props}
      />
    </div>
  );
}
