import { Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as SubframeCore from "@subframe/core";
import { ERROR_CODE } from "constants/constants";
import dayjs from "dayjs";
import useUser from "hooks/userHooks";
import {
  INBOX_DATE_FILTER_OPTIONS,
  INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS,
  INBOX_ESCALATION_TYPE,
  INBOX_SORT_MAPPER,
  INBOX_TABS,
  SENTIMENT,
  SORT_BY_OPTIONS,
} from "modules/Retention/constants/constants";
import {
  fetchBranchMetadata,
  fetchEscalations,
  fetchInboxSummary,
  fetchTurnoverRateData,
  fetchWinsAndOpportunities,
} from "modules/Retention/services/retention";
import { renderEmploymentStatus } from "modules/Retention/utils/common";
import { renderSentimentBadgeWithScore } from "modules/Retention/utils/sentiment";
import { useEffect, useRef, useState } from "react";
import MultiProgress from "react-multi-progress";
import { useNavigate, useParams } from "react-router-dom";
import { CustomBadge, InboxHeader, Loader, Select, TabsPills } from "subframe";
import { Accordion } from "subframe/components/Accordion";
import { Badge } from "subframe/components/Badge";
import { CustomStatCard } from "subframe/components/CustomStatCard";
import { InboxRow } from "subframe/components/InboxRow";
import { isEmpty as isArrayEmpty, sortArrayObjects } from "utils/array";
import { getLastNDaysFromToday, getRelativeDate } from "utils/date";
import { isNumber } from "utils/number";
import { isEmpty as isObjectEmpty } from "utils/object";
import { getFullName, isEmpty as isStringEmpty } from "utils/string";
import EmptyState from "../EmptyState";
import MetricChart from "../MetricChart";
import SupervisorWithMetadata from "../SupervisorWithMetadata/SupervisorWithMetadata";

const SentimentIndicator = ({
  sentiment_score,
  overall_sentiment,
  surveyResponseRate,
  prev_overall_sentiment_score,
  time_comparison = "last survey", // default value as a string constant
}) => {
  if (!isNumber(sentiment_score)) {
    return <>-</>;
  }

  const canCalculateDifference = isNumber(prev_overall_sentiment_score);
  const difference = canCalculateDifference
    ? sentiment_score - prev_overall_sentiment_score
    : null;

  const is_positive = difference > 0;
  const is_negative = difference < 0;
  const no_change = difference === 0;

  const percentageChange =
    canCalculateDifference && prev_overall_sentiment_score !== 0
      ? (difference / prev_overall_sentiment_score) * 100
      : null;

  const sentimentObj =
    surveyResponseRate === 0
      ? SENTIMENT["No Data"]
      : SENTIMENT[overall_sentiment] ?? SENTIMENT["No Data"];
  const scoreLabel = `${sentimentObj.label} ${
    surveyResponseRate === 0 ? "" : `(${sentiment_score?.toString()})`
  }`;

  const borderColor = sentimentObj.sentimentTextColor;
  const textColor = is_positive
    ? "text-success-700"
    : is_negative
    ? "text-error-700"
    : "text-neutral-700";
  const bgColor = is_positive
    ? "bg-[#dcfce7ff]"
    : is_negative
    ? "bg-error-100"
    : "bg-neutral-100";
  const borderInnerColor = is_positive
    ? "border-success-200"
    : is_negative
    ? "border-error-200"
    : "border-neutral-200";
  const iconName = is_positive
    ? "FeatherArrowUpRight"
    : is_negative
    ? "FeatherArrowDownRight"
    : null;

  return (
    <div
      className={`flex flex-col items-start gap-1 border-l-4 border-solid px-2 py-1`}
      style={{
        borderColor,
      }}
    >
      <span
        className={`text-label-bold font-label-bold ${textColor}`}
        style={{
          color: borderColor,
        }}
      >
        {scoreLabel}
      </span>
      {no_change ? (
        <span
          className={`font-['Nunito'] text-[12px] font-[400] leading-[16px] ${textColor}`}
        >
          No change (vs {time_comparison})
        </span>
      ) : (
        isNumber(percentageChange) && (
          <div
            className={`flex items-center justify-center rounded-md border border-solid ${borderInnerColor} ${bgColor} px-0.5 py-0.5`}
          >
            {iconName && (
              <SubframeCore.Icon
                className={`text-body font-body ${textColor}`}
                name={iconName}
              />
            )}
            <span
              className={`font-['Nunito'] text-[12px] font-[400] leading-[16px] ${textColor}`}
            >
              {Math.abs(percentageChange).toFixed(1)}% (vs {time_comparison})
            </span>
          </div>
        )
      )}
    </div>
  );
};

const BranchDetails = () => {
  const defaultInboxEmployeeStatusFilter =
    INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS["Active"];
  const defaultSort = SORT_BY_OPTIONS["Oldest to Newest"];
  const defaultInboxDateFilter = INBOX_DATE_FILTER_OPTIONS["Last 7 days"];

  const { name } = useParams();
  const escalationListRef = useRef(null);
  const { user, isLoading: isLoadingUser } = useUser();

  const navigate = useNavigate();

  const [atRisks, setAtRisks] = useState({});
  const [inProgress, setInProgress] = useState([]);
  const [dismissed, setDismissed] = useState([]);
  const [resolved, setResolved] = useState([]);
  const [wins, setWins] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [inboxDateFilter, setInboxDateFilter] = useState(null);
  const [inboxEmployeeStatusFilter, setInboxEmployeeStatusFilter] = useState({
    employeeStatus: defaultInboxEmployeeStatusFilter.value,
  });
  const [isLoadingResolved, setIsLoadingResolved] = useState(false);
  const [isLoadingDismissed, setIsLoadingDismissed] = useState(false);
  const [branchMetadata, setBranchMetadata] = useState({});
  const [selectedTab, setSelectedTab] = useState("At-risk");
  const [turnoverRateData, setTurnoverRateData] = useState({});
  const [isLoadingAtRisks, setIsLoadingAtRisks] = useState(false);
  const [isLoadingInProgress, setIsLoadingInProgress] = useState(false);
  const [isLoadingTurnoverRate, setIsLoadingTurnoverRate] = useState(false);
  const [isLoadingBranchMetadata, setIsLoadingBranchMetadata] = useState(false);
  const [isLoadingWinsAndOpportunities, setIsLoadingWinsAndOpportunities] =
    useState(false);
  const [isInboxSummaryLoading, setIsInboxSummaryLoading] = useState(false);
  const [selectedInboxDateFilter, setSelectedInboxDateFilter] = useState(
    defaultInboxDateFilter.label
  );
  const [inboxSummary, setInboxSummary] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [selectedSort, setSelectedSort] = useState(defaultSort);
  const [
    selectedInboxEmployeeStatusFilter,
    setSelectedInboxEmployeeStatusFilter,
  ] = useState(defaultInboxEmployeeStatusFilter.label);

  useEffect(() => {
    if (isLoadingUser || isObjectEmpty(user)) {
      return;
    }
    const dateFilter =
      user.configuration?.default_date_filter || defaultInboxDateFilter;
    setSelectedInboxDateFilter(dateFilter?.label);
    handleGetLastNDaysFromToday(dateFilter?.value);
  }, [user]);

  useEffect(() => {
    if (isStringEmpty(name)) {
      return;
    }

    handleFetchBranchMetadata();
    handleFetchTurnoverRateData();
    handleFetchWinsAndOpportunities();
  }, [name]);

  useEffect(() => {
    if (
      !isObjectEmpty(inboxDateFilter) &&
      !isObjectEmpty(inboxEmployeeStatusFilter) &&
      !isStringEmpty(name)
    ) {
      handleFetchEscalationData();
    }
  }, [inboxDateFilter, inboxEmployeeStatusFilter, name]);

  useEffect(() => {
    setAtRisks({
      overdue: sortArrayObjects(
        atRisks.overdue,
        INBOX_SORT_MAPPER["overdue"][selectedSort.type],
        selectedSort.value
      ),
      new: sortArrayObjects(
        atRisks.new,
        INBOX_SORT_MAPPER["new"][selectedSort.type],
        selectedSort.value
      ),
    });
    setInProgress(
      sortArrayObjects(
        inProgress,
        INBOX_SORT_MAPPER["inProgress"][selectedSort.type],
        selectedSort.value
      )
    );
    setResolved(
      sortArrayObjects(
        resolved,
        INBOX_SORT_MAPPER["resolved"][selectedSort.type],
        selectedSort.value
      )
    );
    setDismissed(
      sortArrayObjects(
        dismissed,
        INBOX_SORT_MAPPER["dismissed"][selectedSort.type],
        selectedSort.value
      )
    );
  }, [selectedSort]);

  const handleGetLastNDaysFromToday = (n = 0) => {
    const result = getLastNDaysFromToday(n);
    const startDate =
      dayjs(result?.startDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.startDate)?.format("YYYY-MM-DD");
    const endDate =
      dayjs(result?.endDate)?.format("YYYY-MM-DD") === "Invalid Date"
        ? null
        : dayjs(result?.endDate)?.format("YYYY-MM-DD");

    setInboxDateFilter({
      ...inboxDateFilter,
      startDate,
      endDate,
    });
  };

  /**
   * Method to fetch the branch metadata.
   *
   * @param {String} name
   * @returns {Promise}
   */
  const fetchBranchMetadataAsync = async (name) => {
    try {
      return await fetchBranchMetadata(name);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to fetch the branch's biggest wins and opportunities.
   *
   * @param {String} name
   * @returns {Promise}
   */
  const fetchWinsAndOpportunitiesAsync = async (name) => {
    try {
      return await fetchWinsAndOpportunities(name);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const handleFetchWinsAndOpportunities = async () => {
    setIsLoadingWinsAndOpportunities(true);
    const response = await fetchWinsAndOpportunitiesAsync({
      branch_name: name,
    });

    setIsLoadingWinsAndOpportunities(false);

    if (response?.hasError) {
      setWins([]);
      setOpportunities([]);
      return;
    }

    setWins(response?.result?.wins);
    setOpportunities(response?.result?.opportunities);
  };

  const handleFetchBranchMetadata = async () => {
    if (isLoadingBranchMetadata) {
      return;
    }

    setIsLoadingBranchMetadata(true);

    const response = await fetchBranchMetadataAsync({ name });

    setIsLoadingBranchMetadata(false);

    if (response?.hasError) {
      setBranchMetadata({});
      return;
    }

    setBranchMetadata(response?.result?.data);
  };

  /**
   * Method to fetch turnover rate data.
   *
   * @param {String} name
   * @returns {Promise}
   */
  const fetchTurnoverRateDataAsync = async (name) => {
    try {
      return await fetchTurnoverRateData(name);
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const handleFetchTurnoverRateData = async () => {
    if (isLoadingTurnoverRate) {
      return;
    }

    setIsLoadingTurnoverRate(true);

    const response = await fetchTurnoverRateDataAsync({ name });

    setIsLoadingTurnoverRate(false);

    if (response?.hasError) {
      setTurnoverRateData({});
      return;
    }

    setTurnoverRateData(response.result);
  };

  const handleFetchEscalationData = () => {
    fetchAndSetInboxSummary({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      branchName: name,
    });
    handleFetchAtRisks({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["AT_RISK"],
      branchName: name,
    });
    handleFetchInProgress({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["IN_PROGRESS"],
      branchName: name,
    });
    handleFetchResolved({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["RESOLVED"],
      branchName: name,
    });
    handleFetchDismissed({
      ...inboxDateFilter,
      ...inboxEmployeeStatusFilter,
      status: INBOX_ESCALATION_TYPE["DISMISSED"],
      branchName: name,
    });
  };

  const fetchAndSetInboxSummary = async (filter = {}) => {
    setIsInboxSummaryLoading(true);
    const response = await fetchAndSetInboxSummaryAsync(filter);

    setIsInboxSummaryLoading(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Inbox Summary: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setInboxSummary({});
    }

    setInboxSummary(response?.result?.escalation_stats);
  };

  const fetchAndSetInboxSummaryAsync = async (filter = {}) => {
    try {
      return await fetchInboxSummary({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        employee_status: filter?.employeeStatus,
        branch_name: filter?.branchName,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const handleStatCardClick = (selectedTab) => {
    setSelectedTab(selectedTab);
    setTimeout(() => {
      escalationListRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const renderAtRisks = () => {
    return (
      <>
        <div className="w-full flex-col items-start flex">
          <InboxHeader id="overdue-section">
            <span className="w-full text-default-font text-subheader font-subheader">
              Overdue
            </span>
          </InboxHeader>
          <div className="w-full flex-col items-start flex max-h-[340px] overflow-auto">
            {isLoadingAtRisks ? (
              <div className="gap-2 w-full flex-col flex">
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
              </div>
            ) : isArrayEmpty(atRisks?.overdue) ? (
              <div className="h-48 w-full">
                <EmptyState
                  icon={
                    <SubframeCore.Icon
                      className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                      name="FeatherFileCheck"
                    />
                  }
                  primaryText="No overdue escalations yet."
                  secondaryText="Great job! You’re all done following-up with at-risk employees."
                />
              </div>
            ) : (
              atRisks.overdue.map((atRisk, index) => {
                return (
                  <InboxRow
                    className={
                      "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                    }
                    key={index}
                  >
                    {renderEmploymentStatus(atRisk.employee_status)}
                    <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                      <span className="text-default-font text-body-bold font-body-bold">
                        {isStringEmpty(
                          getFullName(atRisk.first_name, atRisk.last_name)
                        )
                          ? "-"
                          : getFullName(atRisk.first_name, atRisk.last_name)}
                      </span>
                      <span className="text-subtext-color text-label font-label">
                        {isStringEmpty(atRisk.job_title)
                          ? "-"
                          : atRisk.job_title}
                        ,{" "}
                        {isStringEmpty(atRisk.office_location)
                          ? "-"
                          : atRisk.office_location}
                        , {isStringEmpty(atRisk.region) ? "-" : atRisk.region}
                      </span>
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      <SentimentIndicator
                        sentiment_score={atRisk.sentiment_score}
                        overall_sentiment={atRisk.overall_sentiment}
                        surveyResponseRate={atRisk.response_rate}
                        prev_overall_sentiment_score={
                          atRisk.prev_overall_sentiment_score
                        }
                      />
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      <CustomBadge variant="neutral">
                        {isStringEmpty(atRisk.survey_titles[0])
                          ? "-"
                          : atRisk.survey_titles[0]}
                      </CustomBadge>
                    </div>
                    <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                      {isStringEmpty(atRisk.supervisor_info?.id) ? (
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherUser"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {isStringEmpty(atRisk.supervisor)
                              ? "-"
                              : atRisk.supervisor}
                          </span>
                        </div>
                      ) : (
                        <SupervisorWithMetadata
                          name={atRisk.supervisor_info?.name}
                          id={+atRisk.supervisor_info.id}
                        />
                      )}
                    </div>
                    <div className="grow shrink-0 basis-0 whitespace-nowrap md:whitespace-normal w-fit md:w-full h-full flex flex-col justify-center">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendar"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(atRisk.tenure) ? "-" : atRisk.tenure}
                        </span>
                      </div>
                    </div>
                    <div className="grow shrink-0 basis-0 whitespace-nowrap md:whitespace-normal w-fit md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendarDays"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {getRelativeDate(atRisk.escalated_on)}
                        </span>
                      </div>
                    </div>
                  </InboxRow>
                );
              })
            )}
          </div>
        </div>
        <div className="w-full flex-col items-start flex">
          <InboxHeader>
            <span className="w-full text-default-font text-subheader font-subheader">
              New
            </span>
          </InboxHeader>
          <div className="w-full flex-col items-start flex max-h-[340px] overflow-auto">
            {isLoadingAtRisks ? (
              <div className="gap-2 w-full flex-col flex">
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
                <Skeleton variant="rounded" width="100%" height="50px" />
              </div>
            ) : isArrayEmpty(atRisks?.new) ? (
              <div className="h-48 w-full">
                <EmptyState
                  icon={
                    <SubframeCore.Icon
                      className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                      name="FeatherFileCheck"
                    />
                  }
                  primaryText="No new escalations yet."
                  secondaryText="Great job! You’re all done following-up with at-risk employees."
                />
              </div>
            ) : (
              atRisks.new.map((atRisk, index) => {
                return (
                  <InboxRow
                    className={
                      "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                    }
                    key={index}
                  >
                    {renderEmploymentStatus(atRisk.employee_status)}
                    <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                      <span className="text-default-font text-body-bold font-body-bold">
                        {isStringEmpty(
                          getFullName(atRisk.first_name, atRisk.last_name)
                        )
                          ? "-"
                          : getFullName(atRisk.first_name, atRisk.last_name)}
                      </span>
                      <span className="text-subtext-color text-label font-label">
                        {isStringEmpty(atRisk.job_title)
                          ? "-"
                          : atRisk.job_title}
                        ,{" "}
                        {isStringEmpty(atRisk.office_location)
                          ? "-"
                          : atRisk.office_location}
                        , {isStringEmpty(atRisk.region) ? "-" : atRisk.region}
                      </span>
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      <SentimentIndicator
                        sentiment_score={atRisk.sentiment_score}
                        overall_sentiment={atRisk.overall_sentiment}
                        surveyResponseRate={atRisk.response_rate}
                        prev_overall_sentiment_score={
                          atRisk.prev_overall_sentiment_score
                        }
                      />
                    </div>
                    <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                      <CustomBadge variant="neutral">
                        {isStringEmpty(atRisk.survey_titles[0])
                          ? "-"
                          : atRisk.survey_titles[0]}
                      </CustomBadge>
                    </div>
                    <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                      {isStringEmpty(atRisk.supervisor_info?.id) ? (
                        <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                          <SubframeCore.Icon
                            className="text-default-font text-body-bold font-body-bold"
                            name="FeatherUser"
                          />
                          <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                            {isStringEmpty(atRisk.supervisor)
                              ? "-"
                              : atRisk.supervisor}
                          </span>
                        </div>
                      ) : (
                        <SupervisorWithMetadata
                          name={atRisk.supervisor_info?.name}
                          id={+atRisk.supervisor_info.id}
                        />
                      )}
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendar"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(atRisk.tenure) ? "-" : atRisk.tenure}
                        </span>
                      </div>
                    </div>
                    <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherCalendarDays"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {getRelativeDate(atRisk.escalated_on)}
                        </span>
                      </div>
                    </div>
                  </InboxRow>
                );
              })
            )}
          </div>
        </div>
      </>
    );
  };

  const renderInProgress = () => {
    return (
      <>
        <div
          id="in-progress-section"
          className="w-full flex-col items-start flex flex-1 overflow-auto"
        >
          {isLoadingInProgress ? (
            <div className="gap-2 w-full flex-col flex">
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
            </div>
          ) : isArrayEmpty(inProgress) ? (
            <div className="h-[250px] w-full">
              <EmptyState
                icon={
                  <SubframeCore.Icon
                    className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                    name="FeatherFileBox"
                  />
                }
                primaryText="No in progress escalations yet."
                secondaryText="Follow-up with at-risk employees."
              />
            </div>
          ) : (
            inProgress.map((item) => {
              return (
                <InboxRow
                  className={
                    "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                  }
                >
                  {renderEmploymentStatus(item.employee_status)}
                  <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                    <span className="text-default-font text-body-bold font-body-bold">
                      {isStringEmpty(
                        getFullName(item.first_name, item.last_name)
                      )
                        ? "-"
                        : getFullName(item.first_name, item.last_name)}
                    </span>
                    <span className="text-subtext-color text-label font-label">
                      {isStringEmpty(item.job_title) ? "-" : item.job_title},{" "}
                      {isStringEmpty(item.office_location)
                        ? "-"
                        : item.office_location}
                      , {isStringEmpty(item.region) ? "-" : item.region}
                    </span>
                  </div>
                  <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                    <SentimentIndicator
                      sentiment_score={item.sentiment_score}
                      overall_sentiment={item.overall_sentiment}
                      surveyResponseRate={item.response_rate}
                      prev_overall_sentiment_score={
                        item.prev_overall_sentiment_score
                      }
                    />
                  </div>

                  <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                    {isStringEmpty(item.supervisor_info?.id) ? (
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherUser"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(item.supervisor)
                            ? "-"
                            : item.supervisor}
                        </span>
                      </div>
                    ) : (
                      <SupervisorWithMetadata
                        name={item.supervisor_info?.name}
                        id={+item.supervisor_info.id}
                      />
                    )}
                  </div>
                  <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                    <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                      <SubframeCore.Icon
                        className="text-default-font text-body-bold font-body-bold"
                        name="FeatherCalendar"
                      />
                      <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                        {isStringEmpty(item.tenure) ? "-" : item.tenure}
                      </span>
                    </div>
                  </div>
                  <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                    <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                      <SubframeCore.Icon
                        className="text-default-font text-body-bold font-body-bold"
                        name="FeatherCalendarDays"
                      />
                      <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                        {getRelativeDate(item.in_progress_on)}
                      </span>
                    </div>
                  </div>
                </InboxRow>
              );
            })
          )}
        </div>
      </>
    );
  };

  const renderResolved = () => {
    return (
      <>
        <div
          id="resolved-section"
          className="w-full flex-col items-start flex flex-1 overflow-auto"
        >
          {isLoadingResolved ? (
            <div className="gap-2 w-full flex-col flex">
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
            </div>
          ) : isArrayEmpty(resolved) ? (
            <div className="h-[250px] w-full">
              <EmptyState
                icon={
                  <SubframeCore.Icon
                    className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                    name="FeatherFileBox"
                  />
                }
                primaryText="No resolved escalations yet."
                secondaryText="Follow-up with at-risk employees."
              />
            </div>
          ) : (
            resolved.map((item) => {
              return (
                <InboxRow
                  className={
                    "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                  }
                >
                  {renderEmploymentStatus(item.employee_status)}
                  <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                    <span className="text-default-font text-body-bold font-body-bold">
                      {isStringEmpty(
                        getFullName(item.first_name, item.last_name)
                      )
                        ? "-"
                        : getFullName(item.first_name, item.last_name)}
                    </span>
                    <span className="text-subtext-color text-label font-label">
                      {isStringEmpty(item.job_title) ? "-" : item.job_title},{" "}
                      {isStringEmpty(item.office_location)
                        ? "-"
                        : item.office_location}
                      , {isStringEmpty(item.region) ? "-" : item.region}
                    </span>
                  </div>
                  <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                    <SentimentIndicator
                      sentiment_score={item.sentiment_score}
                      overall_sentiment={item.overall_sentiment}
                      surveyResponseRate={item.response_rate}
                      prev_overall_sentiment_score={
                        item.prev_overall_sentiment_score
                      }
                    />
                  </div>
                  <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                    <CustomBadge variant="neutral">
                      {isStringEmpty(item.survey_titles[0])
                        ? "-"
                        : item.survey_titles[0]}
                    </CustomBadge>
                  </div>
                  <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                    {isStringEmpty(item.supervisor_info?.id) ? (
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherUser"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(item.supervisor)
                            ? "-"
                            : item.supervisor}
                        </span>
                      </div>
                    ) : (
                      <SupervisorWithMetadata
                        name={item.supervisor_info?.name}
                        id={+item.supervisor_info.id}
                      />
                    )}
                  </div>
                  <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                    <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                      <SubframeCore.Icon
                        className="text-default-font text-body-bold font-body-bold"
                        name="FeatherCalendar"
                      />
                      <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                        {isStringEmpty(item.tenure) ? "-" : item.tenure}
                      </span>
                    </div>
                  </div>
                  <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                    <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                      <SubframeCore.Icon
                        className="text-default-font text-body-bold font-body-bold"
                        name="FeatherCalendarDays"
                      />
                      <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                        {getRelativeDate(item.resolved_on)}
                      </span>
                    </div>
                  </div>
                </InboxRow>
              );
            })
          )}
        </div>
      </>
    );
  };

  const renderDismissed = () => {
    return (
      <>
        <div
          id="dismissed-section"
          className="w-full flex-col items-start flex flex-1 overflow-auto"
        >
          {isLoadingDismissed ? (
            <div className="gap-2 w-full flex-col flex">
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
              <Skeleton variant="rounded" width="100%" height="50px" />
            </div>
          ) : isArrayEmpty(dismissed) ? (
            <div className="h-[250px] w-full">
              <EmptyState
                icon={
                  <SubframeCore.Icon
                    className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                    name="FeatherFileBox"
                  />
                }
                primaryText="No dismissed escalations yet."
                secondaryText="Follow-up with at-risk employees."
              />
            </div>
          ) : (
            dismissed.map((item) => {
              return (
                <InboxRow
                  className={
                    "mb-4 bg-neutral-50 md:bg-default-background shadow-sm md:shadow-none flex-wrap"
                  }
                >
                  {renderEmploymentStatus(item.employee_status)}
                  <div className="grow shrink-0 w-fit md:basis-0 md:w-full flex-col items-start flex">
                    <span className="text-default-font text-body-bold font-body-bold">
                      {isStringEmpty(
                        getFullName(item.first_name, item.last_name)
                      )
                        ? "-"
                        : getFullName(item.first_name, item.last_name)}
                    </span>
                    <span className="text-subtext-color text-label font-label">
                      {isStringEmpty(item.job_title) ? "-" : item.job_title},{" "}
                      {isStringEmpty(item.office_location)
                        ? "-"
                        : item.office_location}
                      , {isStringEmpty(item.region) ? "-" : item.region}
                    </span>
                  </div>
                  <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                    <SentimentIndicator
                      sentiment_score={item.sentiment_score}
                      overall_sentiment={item.overall_sentiment}
                      surveyResponseRate={item.response_rate}
                      prev_overall_sentiment_score={
                        item.prev_overall_sentiment_score
                      }
                    />
                  </div>
                  <div className="grow shrink-0 whitespace-nowrap md:whitespace-normal w-fit md:basis-0 md:w-full h-full flex-col gap-2 items-start justify-center flex">
                    <CustomBadge variant="neutral">
                      {isStringEmpty(item.survey_titles[0])
                        ? "-"
                        : item.survey_titles[0]}
                    </CustomBadge>
                  </div>
                  <div className="grow shrink-0 sm:basis-0 whitespace-nowrap md:whitespace-normal w-full h-full flex flex-col justify-center">
                    {isStringEmpty(item.supervisor_info?.id) ? (
                      <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                        <SubframeCore.Icon
                          className="text-default-font text-body-bold font-body-bold"
                          name="FeatherUser"
                        />
                        <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                          {isStringEmpty(item.supervisor)
                            ? "-"
                            : item.supervisor}
                        </span>
                      </div>
                    ) : (
                      <SupervisorWithMetadata
                        name={item.supervisor_info?.name}
                        id={+item.supervisor_info.id}
                      />
                    )}
                  </div>
                  <div className="grow shrink-0 basis-0 w-full h-full flex flex-col justify-center">
                    <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                      <SubframeCore.Icon
                        className="text-default-font text-body-bold font-body-bold"
                        name="FeatherCalendar"
                      />
                      <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                        {isStringEmpty(item.tenure) ? "-" : item.tenure}
                      </span>
                    </div>
                  </div>
                  <div className="grow shrink-0 basis-0 w-full h-full flex-col gap-2 items-start justify-center flex">
                    <div className="gap-2 pt-1 pr-1 pb-1 pl-1 items-center flex">
                      <SubframeCore.Icon
                        className="text-default-font text-body-bold font-body-bold"
                        name="FeatherCalendarDays"
                      />
                      <span className="flex items-center text-default-font text-label font-label first-letter:uppercase">
                        {getRelativeDate(item.dismissed_on)}
                      </span>
                    </div>
                  </div>
                </InboxRow>
              );
            })
          )}
        </div>
      </>
    );
  };

  const handleRenderLineItems = () => {
    if (selectedTab === "At-risk") {
      return renderAtRisks();
    }

    if (selectedTab === "In Progress") {
      return renderInProgress();
    }

    if (selectedTab === "Resolved") {
      return renderResolved();
    }

    if (selectedTab === "Dismissed") {
      return renderDismissed();
    }
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Method to handle the fetching of the at risk employees.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchAtRisks = async (filter = {}) => {
    setIsLoadingAtRisks(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingAtRisks(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching At Risks: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setAtRisks({});

      return;
    }

    setAtRisks({
      overdue: sortArrayObjects(
        response?.result?.data?.overdue,
        INBOX_SORT_MAPPER["overdue"][selectedSort.type],
        selectedSort.value
      ),
      new: sortArrayObjects(
        response?.result?.data?.new,
        INBOX_SORT_MAPPER["new"][selectedSort.type],
        selectedSort.value
      ),
    });
  };

  /**
   * Method to fetch at escalations by API call.
   *
   * @param {Object} filter
   * @returns {Promise}
   */
  const fetchEscalationsAsync = async (filter = {}) => {
    try {
      return await fetchEscalations({
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        status: filter?.status,
        employee_status: filter?.employeeStatus,
        branch_name: filter?.branchName,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Method to handle the fetching of the in progress employees.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchInProgress = async (filter = {}) => {
    setIsLoadingInProgress(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingInProgress(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching In Progress: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setInProgress([]);

      return;
    }

    setInProgress(
      sortArrayObjects(
        response?.result?.data?.in_progress,
        INBOX_SORT_MAPPER["inProgress"][selectedSort.type],
        selectedSort.value
      )
    );
  };

  /**
   * Method to handle the fetching of the resolved employee escalation.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchResolved = async (filter = {}) => {
    setIsLoadingResolved(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingResolved(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Resolved: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setResolved([]);

      return;
    }

    setResolved(
      sortArrayObjects(
        response?.result?.data?.escalations,
        INBOX_SORT_MAPPER["resolved"][selectedSort.type],
        selectedSort.value
      )
    );
  };

  /**
   * Method to handle the fetching of the dismissed employee escalation.
   *
   * @param {Object} filter
   * @returns {Null | Undefined}
   */
  const handleFetchDismissed = async (filter = {}) => {
    setIsLoadingDismissed(true);
    const response = await fetchEscalationsAsync(filter);

    setIsLoadingDismissed(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Dismissed: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setDismissed([]);

      return;
    }

    setDismissed(
      sortArrayObjects(
        response?.result?.data?.escalations,
        INBOX_SORT_MAPPER["dismissed"][selectedSort.type],
        selectedSort.value
      )
    );
  };

  const renderTabPillLoader = (tab = "") => {
    const loadingStates = {
      "At-risk": isLoadingAtRisks,
      "In Progress": isLoadingInProgress,
      Resolved: isLoadingResolved,
      Dismissed: isLoadingDismissed,
    };

    const isLoading = loadingStates[tab];

    return (
      <>
        <span>{tab}</span>
        {isLoading && (
          <Loader
            size="small"
            className={
              selectedTab === tab ? "text-brand-600" : "text-neutral-400"
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-12 rounded-md bg-default-background px-12 py-12 overflow-auto max-768:gap-6 max-768:px-4 max-768:py-4">
        <div className="flex w-full items-center gap-4 rounded-md bg-default-background px-4 py-4 max-768:px-2 max-768:py-2">
          <SubframeCore.Icon
            className="text-section-header font-section-header text-default-font"
            name="FeatherMapPin"
          />
          <span className="text-header font-header text-default-font">
            {isStringEmpty(name) ? "-" : name}
          </span>
        </div>

        <div className="flex w-full items-start gap-12 px-1 py-1 flex-col sm:flex-row max-768:gap-4 max-768:px-0 max-768:py-0">
          <div className="w-full flex grow shrink-0 basis-0 flex-col items-start gap-4 rounded-lg">
            <div className="flex w-full flex-col items-start gap-1">
              {isLoadingBranchMetadata ? (
                <Skeleton variant="rounded" width="100%" height="95px" />
              ) : (
                <>
                  <div className="w-full flex items-baseline gap-1">
                    <span className="text-section-header font-section-header text-default-font">
                      {isNumber(branchMetadata?.total_branch_employees)
                        ? branchMetadata.total_branch_employees
                        : "-"}
                    </span>
                    <span className="text-body-bold font-body-bold text-default-font">
                      {branchMetadata?.total_branch_employees === 1
                        ? "Employee"
                        : "Employees"}
                    </span>
                  </div>
                  <MultiProgress
                    elements={[
                      {
                        value: branchMetadata?.branch_employee_percentage ?? 0,
                        color: "#3b82f6",
                      },
                    ]}
                    height={30}
                    backgroundColor={"#eff6ff"}
                    round={false}
                    roundLastElement={false}
                  />
                  <div>
                    <span className="font-['Nunito'] text-[14px] font-[500] leading-[16px] text-default-font">
                      {isNumber(branchMetadata?.branch_employee_percentage)
                        ? branchMetadata?.branch_employee_percentage.toFixed(1)
                        : "-"}
                      % of total company
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full flex grow shrink-0 basis-0 flex-col items-start gap-4 rounded-lg">
            <div className="flex w-full flex-col items-start gap-1">
              {isLoadingBranchMetadata ? (
                <Skeleton variant="rounded" width="100%" height="95px" />
              ) : (
                <>
                  <div className="w-full flex items-baseline gap-1">
                    <span className="text-section-header font-section-header text-default-font">
                      {isNumber(branchMetadata?.productive_employees)
                        ? branchMetadata.productive_employees
                        : "-"}
                    </span>
                    <span className="text-body-bold font-body-bold text-default-font">
                      {branchMetadata?.productive_employees === 1
                        ? "Tenured Employee"
                        : "Tenured Employees"}
                    </span>
                  </div>
                  <MultiProgress
                    elements={[
                      {
                        value:
                          branchMetadata?.branch_productive_percentage ?? 0,
                        color: "#3b82f6",
                      },
                    ]}
                    height={30}
                    backgroundColor={"#eff6ff"}
                    round={false}
                    roundLastElement={false}
                  />
                  <div>
                    <span className="font-['Nunito'] text-[14px] font-[500] leading-[16px] text-default-font">
                      {isNumber(branchMetadata?.branch_productive_percentage)
                        ? branchMetadata?.branch_productive_percentage.toFixed(
                            1
                          )
                        : "-"}
                      % of total branch
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full flex grow shrink-0 basis-0 flex-col items-start gap-4 rounded-lg">
            <div className="flex w-full flex-col items-start gap-1">
              {isLoadingBranchMetadata ? (
                <Skeleton variant="rounded" width="100%" height="95px" />
              ) : (
                <>
                  <div className="w-full flex items-baseline gap-1">
                    <span className="text-section-header font-section-header text-error-700">
                      {isNumber(branchMetadata?.total_escalated_employees)
                        ? branchMetadata.total_escalated_employees
                        : "-"}
                    </span>
                    <span className="text-body-bold font-body-bold text-default-font">
                      {branchMetadata?.total_escalated_employees === 1
                        ? "Escalated Employee"
                        : "Escalated Employees"}
                    </span>
                  </div>

                  <MultiProgress
                    elements={[
                      {
                        value: branchMetadata?.branch_escalated_percentage ?? 0,
                        color: "#3b82f6",
                      },
                    ]}
                    height={30}
                    backgroundColor={"#eff6ff"}
                    round={false}
                    roundLastElement={false}
                  />
                  <div>
                    <span className="font-['Nunito'] text-[14px] font-[500] leading-[16px] text-default-font">
                      {isNumber(branchMetadata?.branch_escalated_percentage)
                        ? branchMetadata?.branch_escalated_percentage.toFixed(1)
                        : "-"}
                      % of total branch
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col items-start gap-12 max-768:flex">
          <div className="flex w-full flex-wrap items-start gap-8">
            <div className="flex grow shrink-0 basis-0 flex-col items-start gap-6">
              <div className="flex w-full items-start gap-4 px-2 max-768:flex max-768:h-auto max-768:w-full max-768:flex-none max-768:flex-col max-768:gap-4 max-768:px-0 max-768:py-0">
                <div className="flex w-144 flex-none flex-col items-start self-stretch w-[60%] max-768:w-full max-768:grow max-768:shrink-0 max-768:basis-0 max-768:flex-col max-768:items-start max-768:justify-start max-768:gap-0 max-768:bg-transparent">
                  <div className="flex w-full items-center gap-2 border border-solid border-neutral-border px-4 py-4 max-768:h-auto max-768:w-full max-768:flex-none">
                    <SubframeCore.Icon
                      className="text-body font-body text-default-font"
                      name="FeatherUsers"
                    />
                    <span className="text-body-bold font-body-bold text-default-font">
                      Turnover Rate
                    </span>
                  </div>
                  <div className="flex w-full grow shrink-0 basis-0 items-start gap-9 border border-solid border-neutral-border px-4 py-4 max-1280:flex-col max-1280:gap-9">
                    {!isLoadingTurnoverRate &&
                    isObjectEmpty(turnoverRateData) ? (
                      <EmptyState
                        icon={
                          <SubframeCore.Icon
                            className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                            name="FeatherLineChart"
                          />
                        }
                        primaryText="Turnover Rate Insights Not Available Yet."
                        secondaryText="We’re currently gathering data for this section. Turnover trends and contributing factors will display here once we have more insights."
                      />
                    ) : (
                      <>
                        <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-6 px-1 py-1">
                          <span className="text-body-bold font-body-bold text-subtext-color">
                            Turnover Graph
                          </span>
                          <div className="flex w-full flex-col items-start gap-4">
                            <div className="flex w-full items-center justify-center gap-2 relative">
                              {isLoadingTurnoverRate ? (
                                <Skeleton
                                  variant="rounded"
                                  width="100%"
                                  height="105px"
                                />
                              ) : (
                                <MetricChart
                                  metricName="Branch Turnover"
                                  branchValue={turnoverRateData?.turnover_rate}
                                  peerValue={
                                    turnoverRateData?.peer_avg_turnover?.score
                                  }
                                  companyValue={
                                    turnoverRateData?.company_avg_turnover
                                      ?.score
                                  }
                                  thresholds={{
                                    default: "#171717",
                                    barColors: [
                                      { max: 20, color: "#22c55e" },
                                      { max: 40, color: "#eab308" },
                                      { max: Infinity, color: "#ef4444" },
                                    ],
                                    textColors: [
                                      { max: 20, color: "#15803d" },
                                      { max: 40, color: "#a16207" },
                                      { max: Infinity, color: "#b91c1c" },
                                    ],
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full h-full grow shrink-0 basis-0 flex-col items-start gap-6 px-1 py-1">
                          <div className="flex w-full flex-col items-start gap-2">
                            <span className="text-body-bold font-body-bold text-subtext-color">
                              Contributing Factors
                            </span>
                          </div>
                          <div className="flex w-full h-full flex-col items-start gap-2">
                            {isLoadingTurnoverRate ? (
                              <Skeleton
                                variant="rounded"
                                width="100%"
                                height="100%"
                              />
                            ) : (
                              <>
                                <MetricChart
                                  metricName="Sentiment Score"
                                  branchValue={
                                    turnoverRateData?.sentiment_score
                                  }
                                  peerValue={
                                    turnoverRateData?.peer_avg_sentiment?.score
                                  }
                                  companyValue={
                                    turnoverRateData?.company_avg_sentiment
                                      ?.score
                                  }
                                  thresholds={{
                                    default: "#171717",
                                    barColors: [
                                      { max: Infinity, color: "#3b82f6" },
                                    ],
                                    textColors: [
                                      { max: Infinity, color: "#737373" },
                                    ],
                                  }}
                                  formatValue={(value) => `${value.toFixed(1)}`}
                                />
                                <MetricChart
                                  metricName="Response Rate"
                                  branchValue={turnoverRateData?.response_rate}
                                  peerValue={
                                    turnoverRateData?.peer_avg_response_rate
                                      ?.score
                                  }
                                  companyValue={
                                    turnoverRateData?.company_avg_response_rate
                                      ?.score
                                  }
                                  thresholds={{
                                    default: "#171717",
                                    barColors: [
                                      { max: Infinity, color: "#3b82f6" },
                                    ],
                                    textColors: [
                                      { max: Infinity, color: "#737373" },
                                    ],
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex grow shrink-0 basis-0 flex-col items-start self-stretch w-[70%] max-768:flex max-768:w-[100%]">
                  <div className="flex w-full items-center gap-2 border border-solid border-neutral-border px-4 py-4">
                    <SubframeCore.Icon
                      className="text-body font-body text-default-font"
                      name="FeatherLightbulb"
                    />
                    <span className="text-body-bold font-body-bold text-default-font">
                      Turnover Reduction Tips
                    </span>
                  </div>
                  <div className="flex w-full grow shrink-0 basis-0 items-start gap-9 border border-solid border-neutral-border">
                    <div className="flex grow shrink-0 basis-0 flex-col items-start border-b border-solid border-neutral-border">
                      <Accordion
                        trigger={
                          <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
                            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-default-font">
                              Encourage Employee Engagement
                            </span>
                            <Accordion.Chevron />
                          </div>
                        }
                        defaultOpen={true}
                      >
                        <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 px-6 pb-6">
                          <span className="text-body font-body text-default-font">
                            Conduct regular one-on-ones to learn what makes each
                            employee feel valued. Recognize achievements in ways
                            that match their preferences, building a strong
                            sense of appreciation and belonging.
                          </span>
                        </div>
                      </Accordion>
                      <Accordion
                        trigger={
                          <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
                            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-default-font">
                              Improve Team Communication
                            </span>
                            <Accordion.Chevron />
                          </div>
                        }
                      >
                        <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 px-6 pb-6">
                          <span className="text-body font-body text-default-font">
                            Maintain open communication with daily or weekly
                            stand-ups and monthly in-person meetings. Use an
                            open-door policy to foster transparency, ensuring
                            employees feel their feedback is valued.
                          </span>
                        </div>
                      </Accordion>
                      <Accordion
                        trigger={
                          <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
                            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-default-font">
                              Support Career Development
                            </span>
                          </div>
                        }
                      >
                        <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-2 px-6 pb-6">
                          <span className="text-body font-body text-default-font">
                            Provide support by setting early goals and
                            scheduling regular check-ins. Discuss educational
                            resources and growth paths to help employees see a
                            future in the organization.
                          </span>
                        </div>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col items-start gap-8 mobile:hidden">
            <div className="flex grow w-full gap-4 justify-between md:justify-end">
              {isLoadingAtRisks ||
              isLoadingInProgress ||
              isLoadingResolved ||
              isLoadingDismissed ? (
                <Skeleton variant="rounded" width="120px" height="35px" />
              ) : (
                <Select
                  className="flex-none w-[calc(50%-1rem)] md:w-auto h-9"
                  placeholder="Status"
                  size="Small"
                  value={selectedInboxEmployeeStatusFilter}
                  onValueChange={(label) => {
                    setSelectedInboxEmployeeStatusFilter(label);
                    setInboxEmployeeStatusFilter({
                      employeeStatus:
                        INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS[label].value,
                    });
                  }}
                >
                  {Object.keys(INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS).map(
                    (key) => (
                      <Select.Item
                        value={INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS[key].label}
                        key={INBOX_EMPLOYEE_STATUS_FILTER_OPTIONS[key].value}
                      />
                    )
                  )}
                </Select>
              )}
              {isLoadingAtRisks ||
              isLoadingInProgress ||
              isLoadingResolved ||
              isLoadingDismissed ? (
                <Skeleton variant="rounded" width="120px" height="35px" />
              ) : (
                <Select
                  className="flex-none w-[calc(50%-1rem)] md:w-auto h-9"
                  placeholder="Last 7 days"
                  size="Small"
                  value={selectedInboxDateFilter}
                  onValueChange={(label) => {
                    setSelectedInboxDateFilter(label);
                    handleGetLastNDaysFromToday(
                      INBOX_DATE_FILTER_OPTIONS[label].value
                    );
                  }}
                >
                  {Object.keys(INBOX_DATE_FILTER_OPTIONS).map((key) => (
                    <Select.Item
                      value={INBOX_DATE_FILTER_OPTIONS[key].label}
                      key={INBOX_DATE_FILTER_OPTIONS[key].value}
                    />
                  ))}
                </Select>
              )}
            </div>

            <div className="w-full gap-4 items-center flex flex-wrap justify-between md:flex-nowrap">
              {isInboxSummaryLoading ? (
                <>
                  <Skeleton variant="rounded" width="100%" height="85px" />
                  <Skeleton variant="rounded" width="100%" height="85px" />
                  <Skeleton variant="rounded" width="100%" height="85px" />
                  <Skeleton variant="rounded" width="100%" height="85px" />
                </>
              ) : (
                <>
                  <CustomStatCard
                    title="At-risk"
                    value={
                      !isNumber(inboxSummary?.active_count)
                        ? "-"
                        : inboxSummary?.active_count?.toString()
                    }
                    variant="at-risk"
                    onClick={() => handleStatCardClick("At-risk")}
                    className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
                  />
                  <CustomStatCard
                    title="In Progress"
                    value={
                      !isNumber(inboxSummary?.in_progress_count)
                        ? "-"
                        : inboxSummary?.in_progress_count?.toString()
                    }
                    variant="escalated"
                    onClick={() => handleStatCardClick("In Progress")}
                    className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
                  />
                  <CustomStatCard
                    title="Resolved"
                    value={
                      !isNumber(inboxSummary?.resolved_count)
                        ? "-"
                        : inboxSummary?.resolved_count?.toString()
                    }
                    variant="resolved"
                    onClick={() => handleStatCardClick("Resolved")}
                    className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
                  />
                  <CustomStatCard
                    title="Dismissed"
                    value={
                      !isNumber(inboxSummary?.dismiss_count)
                        ? "-"
                        : inboxSummary?.dismiss_count?.toString()
                    }
                    onClick={() => handleStatCardClick("Dismissed")}
                    className="hover:shadow-md transition-shadow hover:cursor-pointer max-w-[calc(50%-1rem)] md:max-w-none"
                  />
                </>
              )}
            </div>

            <div className="w-full justify-between flex-wrap items-start flex gap-4 md:gap-0 flex-col-reverse md:flex-row">
              <TabsPills
                ref={escalationListRef}
                className="w-auto h-auto flex-wrap pt-2"
                style={{ scrollMarginTop: "6rem" }}
              >
                {INBOX_TABS.map((tab, index) => (
                  <TabsPills.Item
                    active={selectedTab === tab}
                    value={tab}
                    onClick={() => setSelectedTab(tab)}
                    key={index}
                  >
                    <div className="flex justify-between sm:justify-center items-center sm:items-center gap-1">
                      {renderTabPillLoader(tab)}
                    </div>
                  </TabsPills.Item>
                ))}
              </TabsPills>
              <div className="gap-2 items-center flex w-full md:w-auto">
                <Select
                  className="flex-none h-9 w-full md:w-auto"
                  placeholder="Sort by"
                  size="Small"
                  defaultValue={defaultSort.label}
                  onValueChange={(value) =>
                    setSelectedSort(SORT_BY_OPTIONS[value])
                  }
                >
                  {Object.keys(SORT_BY_OPTIONS).map((key) => {
                    return (
                      <Select.Item
                        value={SORT_BY_OPTIONS[key].label}
                        key={key}
                      />
                    );
                  })}
                </Select>
              </div>
            </div>

            <div id="escalations" className="w-full">
              {handleRenderLineItems()}
            </div>
          </div>
        </div>

        <div className="flex w-full flex-wrap items-start gap-8 max-768:flex-col max-768:flex-wrap max-768:gap-4">
          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-6 self-stretch">
            <div className="flex w-full flex-col items-start gap-4 rounded-md">
              <span className="text-subheader font-subheader text-success-700">
                Biggest Wins
              </span>
              {isLoadingWinsAndOpportunities ? (
                <Skeleton variant="rounded" width="100%" height="448px" />
              ) : (
                <div className="flex max-h-[448px] w-full flex-col items-start gap-4 overflow-auto pr-4">
                  {isArrayEmpty(wins) ? (
                    <div className="h-[448px] w-full">
                      <EmptyState
                        icon={
                          <SubframeCore.Icon
                            className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                            name="FeatherInbox"
                          />
                        }
                        primaryText="No Biggest Wins recorded yet."
                        secondaryText="We’ll showcase this branch’s top achievements as they’re recognized."
                      />
                    </div>
                  ) : (
                    wins.map((data, index) => (
                      <div
                        key={data.question_id || index}
                        className="flex w-full flex-col items-start gap-2"
                      >
                        <div className="flex w-full items-center justify-between">
                          <span className="text-body-bold font-body-bold text-default-font">
                            {data.question_category}
                          </span>
                          {renderSentimentBadgeWithScore(
                            data.sentiment_score,
                            data.sentiment,
                            100 // assuming non 0 data here as field is not present in the API response
                          )}
                        </div>
                        <span className="text-body font-body text-subtext-color">
                          {data.question}
                        </span>
                        <Badge variant="neutral">{data.job_title}</Badge>

                        {/* Divider */}
                        {index < wins.length - 1 && (
                          <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200" />
                        )}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex grow shrink-0 basis-0 flex-col items-start gap-6 self-stretch">
            <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 rounded-md">
              <span className="text-subheader font-subheader text-warning-600">
                Improvement Opportunities
              </span>
              {isLoadingWinsAndOpportunities ? (
                <Skeleton variant="rounded" width="100%" height="448px" />
              ) : (
                <div className="flex max-h-[448px] w-full flex-col items-start gap-4 overflow-auto pr-4">
                  {isArrayEmpty(opportunities) ? (
                    <div className="h-[448px] w-full">
                      <EmptyState
                        icon={
                          <SubframeCore.Icon
                            className="text-[44px] font-[100] leading-[44px] text-neutral-400"
                            name="FeatherInbox"
                          />
                        }
                        primaryText="No Improvement Opportunities identified yet."
                        secondaryText="You’re all set for now! Any focus areas will appear here as they arise."
                      />
                    </div>
                  ) : (
                    <>
                      {opportunities.map((data, index) => (
                        <div
                          key={data.question_id || index}
                          className="flex w-full flex-col items-start gap-2"
                        >
                          <div className="flex w-full items-center justify-between">
                            <span className="text-body-bold font-body-bold text-default-font">
                              {data.question_category}
                            </span>
                            {renderSentimentBadgeWithScore(
                              data.sentiment_score,
                              data.sentiment,
                              100 // assuming non 0 data here as field is not present in the API response
                            )}
                          </div>
                          <span className="text-body font-body text-subtext-color">
                            {data.question}
                          </span>
                          <Badge variant="neutral">{data.job_title}</Badge>

                          {index < opportunities.length - 1 && (
                            <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200" />
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {renderErrorSnackbar()}
      </div>
    </>
  );
};

export default BranchDetails;
