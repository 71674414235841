import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "@subframe/core";
import { AppBar, Box, Snackbar, Toolbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import Logo from "../../../../assets/images/logoV2.svg";
import playLogo from "../../../../assets/images/play-circle-icon.png";
import {
  fetchEscalationVideoTracking,
  updateEscalationVideoTracking,
} from "modules/Retention/services/retention";

const EscalationVideoTracker = () => {
  const { id } = useParams();
  const videoRef = useRef(null);
  const [videoStarted, setVideoStarted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loadingVideoUrl, setLoadingVideoUrl] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const lastTrackedProgress = useRef(0);
  const [showPlayBtn, setShowPlayBtn] = useState(false);

  const handlePlay = () => {
    if (!videoStarted) {
      setShowPlayBtn(false);
      setVideoStarted(true);
      updateVideoTrackingInfo({
        tracking_id: id,
        video_played: true,
      });
    }
  };

  const handleEnded = () => {
    updateVideoTrackingInfo({
      tracking_id: id,
      video_completed: true,
    });
  };

  const trackProgress = () => {
    const video = videoRef.current;
    if (video) {
      const currentTime = video.currentTime;
      const roundedTime = Math.floor(currentTime);

      // Use useRef to track last progress without triggering re-renders
      if (roundedTime > lastTrackedProgress.current && roundedTime % 5 === 0) {
        lastTrackedProgress.current = roundedTime;
        updateVideoTrackingInfo({
          tracking_id: id,
          video_progress: currentTime,
        });
      }
    }
  };

  const fetchEscalationVideoUrlAsync = async (trackingId) => {
    try {
      return await fetchEscalationVideoTracking(trackingId);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const updateEscalationVideoUrlAsync = async (payload) => {
    try {
      return await updateEscalationVideoTracking(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const fetchVideoTrackingInfo = async () => {
    setLoadingVideoUrl(true);
    const response = await fetchEscalationVideoUrlAsync(id);
    setLoadingVideoUrl(false);
    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);
      return;
    }

    if (response?.data?.result?.video_url) {
      setVideoUrl(response?.data?.result?.video_url);
    }
  };

  const updateVideoTrackingInfo = async (payload) => {
    const response = await updateEscalationVideoUrlAsync(payload);
    if (response?.hasError) {
      setErrorMessage(
        `Error while updating: ${response?.error?.response?.data?.message}`
      );
      setIsErrorMessageShown(true);
    }
  };

  useEffect(() => {
    fetchVideoTrackingInfo();

    let animationFrameId;

    const trackProgressLogic = () => {
      const video = videoRef.current;
      if (video && !video.paused) {
        trackProgress();
      }
      animationFrameId = requestAnimationFrame(trackProgressLogic);
    };

    animationFrameId = requestAnimationFrame(trackProgressLogic);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [videoUrl]);

  useEffect(() => {
    if (videoUrl && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Auto-play failed:", error);
        setShowPlayBtn(true);
      });
    }
  }, [videoUrl]);

  useEffect(() => {
    if (videoUrl && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Auto-play failed:", error);
        setShowPlayBtn(true);
      });
    }
  }, [videoUrl]);

  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <div className="w-full">
      <AppBar
        position="sticky"
        sx={{
          top: 0,
          zIndex: 99,
          height: "75px",
          backgroundColor: "#eff6ff",
          color: "#2563eb",
          boxShadow: "none",
          fontFamily: "Nunito",
        }}
        className="sticky"
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box display="flex" alignItems="center">
            <img src={Logo} className="h-6 flex-none" alt="levo-logo" />
          </Box>
        </Toolbar>
      </AppBar>
      {!loadingVideoUrl ? (
        <div
          style={{ background: "linear-gradient(to bottom, #95D5FF, #099bf8)" }}
        >
          <div className="relative w-full h-[calc(100vh-75px)]">
            <video
              ref={videoRef}
              src={videoUrl}
              controls
              onPlay={handlePlay}
              onEnded={handleEnded}
              className="m-auto h-[calc(100vh-75px)] cursor-pointer"
              autoPlay={true}
            />
            {showPlayBtn && (
              <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
                <img src={playLogo} className="w-24 h-24" alt="play button" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full h-[calc(100vh-75px)] text-center flex justify-center items-center">
          <Loader size="small" />
        </div>
      )}
      {renderErrorSnackbar()}
    </div>
  );
};

export default EscalationVideoTracker;
