import StyledDataGrid from "../../components/shared/StyledDataGrid";
import GridToolbar from '../shared/GridToolbar/GridToolbar';

export default function HeadcountGridView({
  rows,
  columns,
  rowCount,
  isLoading,
  paginationModel,
  setPaginationModel,
  fetchAllData,
  ...props
}) {
  const handlePageChange = (newPage) => {
    setPaginationModel((old) => ({ ...old, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel((old) => ({ ...old, pageSize: newPageSize }));
  };

  const renderCell = (content, className = "") => {
    return (
      <div className={`custom-datagrid-cell-wrapper ${className}`}>
        {content}
      </div>
    );
  };

  const renderPercentageCell = (value, colorClass) => {
    return renderCell(
      `${value}%`,
      `text-center rounded-md py-1 px-2 mx-2 ${colorClass}`
    );
  };

  const enhancedColumns = [
    ...columns.map((column) => ({
      ...column,
      headerClassName: "super-app-theme--header",
      renderCell: column.renderCell || ((params) => {
        if (column.field === "new_hire_percentage") {
          return renderPercentageCell(
            params.value,
            column.getColorClass?.(params.value, params.row)
          );
        }
        return renderCell(params.value);
      }),
    })),
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 50,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <GridToolbar 
          rows={rowsWithTotals} 
          title="Headcount_Report" 
          columns={enhancedColumns}
          fetchAllData={fetchAllData}
        />
      ),
      renderCell: () => null,
    }
  ];

  // Calculate totals
  const totals = rows.length
    ? rows.reduce(
        (acc, row) => ({
          normalized_headcount:
            (acc.normalized_headcount || 0) +
            (Number(row?.normalized_headcount) || 0),
          total_employees:
            (acc.total_employees || 0) + (Number(row?.total_employees) || 0),
          new_employees:
            (acc.new_employees || 0) + (Number(row?.new_employees) || 0),
          new_hire_percentage:
            (acc.new_hire_percentage || 0) +
            (Number(row?.new_hire_percentage) || 0),
        }),
        {}
      )
    : {
        normalized_headcount: 0,
        total_employees: 0,
        new_employees: 0,
        new_hire_percentage: 0,
      };

  const rowsWithTotals = rows.length
    ? [
        ...rows,
        {
          id: "total",
          job_title: "Total",
          normalized_headcount: Number(totals.normalized_headcount.toFixed(1)),
          total_employees: totals.total_employees,
          new_employees: totals.new_employees,
          new_hire_percentage: Number(
            (totals.new_hire_percentage / (rows.length || 1)).toFixed(1)
          ),
        },
      ]
    : rows;

  return (
    <StyledDataGrid
      rows={rowsWithTotals}
      columns={enhancedColumns}
      rowCount={rowCount}
      loading={isLoading}
      paginationModel={paginationModel}
      onPageChange={(newPage) =>
        setPaginationModel((old) => ({ ...old, page: newPage }))
      }
      onPageSizeChange={(newPageSize) =>
        setPaginationModel((old) => ({ ...old, pageSize: newPageSize }))
      }
      disableColumnMenu
      disableColumnSelector
      getRowClassName={(params) => 
        params.id === "total" ? "pinned font-bold" : ""
      }
      components={{
        Toolbar: () => null
      }}
      {...props}
    />
  );
}
