import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Button } from "subframe";

export default function JobEditModal({
  open,
  onClose,
  jobData,
  onSave,
  isSaving = false,
}) {
  const [formData, setFormData] = useState({
    job_title: "",
    hourly_rate: "",
    refer_rate: "",
    average_shift_length: "",
    work_requirements: "",
    areas_served: "",
    onboarding_instructions: "",
    onboarding_messages: "",
    detail_descriptions: "",
    outreach_message: "",
  });

  useEffect(() => {
    if (jobData) {
      setFormData({
        job_title: jobData.job_title || "",
        hourly_rate: jobData.hourly_rate ?? "",
        refer_rate: jobData.refer_rate ?? "",
        average_shift_length: jobData.average_shift_length ?? "",
        work_requirements: jobData.work_requirements || "",
        areas_served: jobData.areas_served || "",
        onboarding_instructions: jobData.onboarding_instructions || "",
        onboarding_messages: jobData.onboarding_messages || "",
        detail_descriptions: jobData.detail_descriptions || "",
        outreach_message: jobData.outreach_message || "",
      });
    }
  }, [jobData]);

  const handleChange = (field) => (e) => {
    const value =
      field === "additional_screening" ? e.target.checked : e.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    const dataToSubmit = {
      ...formData,
      hourly_rate:
        formData.hourly_rate === "" ? null : parseFloat(formData.hourly_rate),
      refer_rate:
        formData.refer_rate === "" ? null : parseFloat(formData.refer_rate),
    };
    onSave(jobData.id, dataToSubmit);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Job</DialogTitle>
      <DialogContent>
        <div className="space-y-4 py-4">
          <TextField
            fullWidth
            label="Job Title"
            value={formData.job_title}
            onChange={handleChange("job_title")}
          />
          <TextField
            fullWidth
            label="Hourly Rate"
            type="number"
            value={formData.hourly_rate}
            onChange={handleChange("hourly_rate")}
          />
          <TextField
            fullWidth
            label="Referral Rate"
            type="number"
            value={formData.refer_rate}
            onChange={handleChange("refer_rate")}
          />
          <TextField
            fullWidth
            label="Average Shift Length (in hours)"
            type="number"
            value={formData.average_shift_length}
            onChange={handleChange("average_shift_length")}
          />
          <TextField
            fullWidth
            label="Work Requirements"
            multiline
            rows={3}
            value={formData.work_requirements}
            onChange={handleChange("work_requirements")}
          />
          <TextField
            fullWidth
            label="Areas Served"
            value={formData.areas_served}
            onChange={handleChange("areas_served")}
          />
          <TextField
            fullWidth
            label="Onboarding Instructions"
            multiline
            rows={3}
            value={formData.onboarding_instructions}
            onChange={handleChange("onboarding_instructions")}
          />
          <TextField
            fullWidth
            label="Onboarding Messages"
            multiline
            rows={3}
            value={formData.onboarding_messages}
            onChange={handleChange("onboarding_messages")}
          />
          <TextField
            fullWidth
            label="Detail Descriptions"
            multiline
            rows={3}
            value={formData.detail_descriptions}
            onChange={handleChange("detail_descriptions")}
          />
          <TextField
            fullWidth
            label="Outreach Message"
            multiline
            rows={3}
            value={formData.outreach_message}
            onChange={handleChange("outreach_message")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex gap-2">
          <Button
            variant="brand-secondary"
            onClick={onClose}
            className="whitespace-nowrap"
          >
            Cancel
          </Button>
          <Button
            variant="brand-primary"
            onClick={handleSubmit}
            disabled={isSaving}
            className="whitespace-nowrap"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
