const config = {
  authBaseUrl: process.env.REACT_APP_URL,
  environment: process.env.REACT_APP_PROJECT_ENV,
  referralBaseUrl: process.env.REACT_APP_REFERRAL_BASE_URL,
  analyticsClientId: process.env.REACT_APP_LIGHTSKI_CLIENT_ID,
  analyticsEmbedId: process.env.REACT_APP_LIGHTSKI_EMBED_ID,
  analyticsEmbedToken: process.env.REACT_APP_LIGHTSKI_EMBED_TOKEN,
  analyticsHHFEmbedId: process.env.REACT_APP_LIGHTSKI_HHF_EMBED_ID,
  analyticsHHFEmbedToken: process.env.REACT_APP_LIGHTSKI_HHF_EMBED_TOKEN,
  mixpanelProjectToken: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  audioSurveyBaseUrl: process.env.REACT_APP_ALPHARUN_BASE_URL,
  audioSurveyInterviewToken: process.env.REACT_APP_ALPHARUN_INTERVIEW_TOKEN,
  embedDashboardIds: process.env.REACT_APP_METABASE_DASHBOARD_IDS,
  noteSummarySectionTemplate: process.env.REACT_APP_NOTE_SUMMARY_SECTION_TEMPLATE,
  noteSummarySectionRegex: process.env.REACT_APP_NOTE_SUMMARY_SECTION_REGEX,
  noteSummaryTagSectionTemplate: process.env.REACT_APP_NOTE_SUMMARY_TAG_SECTION_TEMPLATE,
  noteSummaryTagSectionRegex: process.env.REACT_APP_NOTE_SUMMARY_TAG_SECTION_REGEX,
  noteSummaryTagMaxWords: process.env.REACT_APP_NOTE_SUMMARY_TAG_MAX_WORDS,
  mixpanelProxyEndpoint: process.env.REACT_APP_MIXPANEL_PROXY_ENDPOINT,
  embedDashboardIdsCaregiver: process.env.REACT_APP_METABASE_DASHBOARD_IDS_CAREGIVER
};

export default config;
