import React, { useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import * as SubframeCore from "@subframe/core";
import EmployeeDetailsTable from '../EmployeeDetailsTable';

const EmployeeDetailsModal = ({ 
  open, 
  onClose, 
  jobTitle,
  jobId,
  fetchEmployees = async () => {} 
}) => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (open && jobId) {
      loadEmployees();
    }
  }, [open, jobId, paginationModel]);

  const loadEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await fetchEmployees({
        job_id: jobId.id || jobId,
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
      });
      
      if (response?.success) {
        setEmployees(response.result.employees);
        setTotalRows(response.result.total);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-3">
            <SubframeCore.Icon
              name="FeatherUsers"
              size={20}
              className="text-gray-600"
            />
            <h2 className="text-xl font-semibold text-gray-900">
              {`${jobTitle} | Employees`}
            </h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <SubframeCore.Icon name="FeatherX" size={24} />
          </button>
        </div>
        
        <EmployeeDetailsTable
          rows={employees}
          isLoading={isLoading}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          rowCount={totalRows}
        />
      </div>
    </Dialog>
  );
};

export default EmployeeDetailsModal; 