/*
 * Documentation:
 * Profile — https://app.subframe.com/library?component=Profile_66177097-af2b-4244-8e39-f3661216eb34
 * Avatar — https://app.subframe.com/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 * Custom Badge — https://app.subframe.com/library?component=Custom+Badge_6c4fd648-485b-4df3-b96e-19a465da6db8
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Profile Action Bar — https://app.subframe.com/library?component=Profile+Action+Bar_a7126f05-9b5e-4b90-a0f2-3bf7f4939d0f
 * Alert — https://app.subframe.com/library?component=Alert_3a65613d-d546-467c-80f4-aaba6a7edcd5
 * Progress — https://app.subframe.com/library?component=Progress_60964db0-a1bf-428b-b9d5-f34cdf58ea77
 * Sentiment Badge — https://app.subframe.com/library?component=Sentiment+Badge_e1962f43-fe6e-498c-9b8e-21e501824ce3
 * Profile Detail Item — https://app.subframe.com/library?component=Profile+Detail+Item_8e9dfadf-1aba-4188-aaae-e694c3b0d82a
 * Accordion — https://app.subframe.com/library?component=Accordion_d2e81e20-863a-4027-826a-991d8910efd9
 * History Survey Question — https://app.subframe.com/library?component=History+Survey+Question_29773008-8eb1-4936-93ea-8e1e0552008a
 */

import MuiAlert from "@mui/material/Alert";
import { Box, Skeleton, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import * as SubframeCore from "@subframe/core";
import React, { useEffect, useState } from "react";
import { Avatar } from "subframe/components/Avatar";
import { CustomBadge } from "subframe/components/CustomBadge";
import { SentimentSummary } from "subframe/components/SentimentSummary";

import { SentimentBadge } from "subframe/components/SentimentBadge";
import { ProfileDetailItem } from "subframe/components/ProfileDetailItem";

import {
  fetchEmployee,
  fetchEmployeeHistory,
} from "modules/Retention/services/retention";
import {
  INBOX_ESCALATION_TYPE,
  SENTIMENT,
} from "modules/Retention/constants/constants";

import { ERROR_CODE } from "constants/constants";

import {
  getAcronym,
  getFullName,
  isEmpty as isStringEmpty,
} from "utils/string";
import { formatDate } from "utils/date";
import { isNumber } from "utils/number";
import { formatPhoneNumber } from "utils/phoneNumber";
import { isEmpty as isObjectEmpty } from "utils/object";

import useUser from "hooks/userHooks";

import History from "../History";
import EscalationFollowUp from "../EscalationFollowUp";
import EmployeeGlanceChart from "../EmployeeGlanceChart";
import RecognitionsAndReferrals from "../RecognitionsAndReferrals";

function EmployeeProfile() {
  const navigate = useNavigate();
  const { id: employeeId } = useParams();

  const { user, isLoading: isLoadingUser } = useUser();

  const [employee, setEmployee] = useState({});
  const [escalationId, setEscalationId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [escalationStatus, setEscalationStatus] = useState();
  const [employeeHistory, setEmployeeHistory] = useState([]);
  const [isFetchingEmployeeHistory, setIsFetchingEmployeeHistory] =
    useState(false);
  const [isFetchingEmployee, setIsFetchingEmployee] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);

  useEffect(() => {
    handleFetchEmployee(employeeId);
    handleFetchEmployeeHistory(employeeId);
  }, []);

  /**
   * Method to fetch the employee profile.
   *
   * @param {Number} employeeId
   */
  const fetchEmployeeAsync = async (employeeId) => {
    try {
      return await fetchEmployee({
        employee_id: employeeId,
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  /**
   * Handles fetching of the employee profile.
   *
   * @param {Number} employeeId
   * @param {Boolean} isSilent
   *
   * @returns {Promise}
   */
  const handleFetchEmployee = async (employeeId, isSilent = false) => {
    !isSilent && setIsFetchingEmployee(true);
    const response = await fetchEmployeeAsync(employeeId);

    !isSilent && setIsFetchingEmployee(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching employee details: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setEmployee({});

      return;
    }

    if (!isObjectEmpty(response?.result?.data)) {
      setEmployee(response?.result?.data);
      setEscalationId(response?.result?.data?.survey?.escalation_id);
      setEscalationStatus(response?.result?.data?.survey?.escalation_status);
    }
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Handles fetching of the employee's history.
   *
   * @param {Number} employeeId
   * @param {Boolean} isSilent
   *
   * @returns {Promise}
   */
  const handleFetchEmployeeHistory = async (
    employeeId,
    enableNoteSummary = false,
    isSilent = false
  ) => {
    !isSilent && setIsFetchingEmployeeHistory(true);
    const response = await fetchEmployeeHistoryAsync(
      employeeId,
      enableNoteSummary
    );

    !isSilent && setIsFetchingEmployeeHistory(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching employee's history: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setEmployeeHistory([]);

      return;
    }

    if (!isObjectEmpty(response?.result?.history)) {
      setEmployeeHistory(response?.result?.history);
    }
  };

  /**
   * Method to fetch the employee's history.
   *
   * @param {Number} employeeId
   */
  const fetchEmployeeHistoryAsync = async (
    employeeId,
    enableNoteSummary = false
  ) => {
    try {
      return await fetchEmployeeHistory({
        employee_id: employeeId,
        enable_llm_note_summary: enableNoteSummary ? "1" : "0",
      });
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const handleEscalationFollowUpSubmit = () => {
    handleFetchEmployee(employeeId);
    handleFetchEmployeeHistory(employeeId);
  };

  const renderLogNotes = () => {
    if (isFetchingEmployee) {
      return (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Skeleton variant="rounded" width="100%" height="150px" />
        </Box>
      );
    }

    const shouldLogNotes =
      !isNumber(escalationId) ||
      (isNumber(escalationId) &&
        (escalationStatus === INBOX_ESCALATION_TYPE["RESOLVED"] ||
          escalationStatus === INBOX_ESCALATION_TYPE["DISMISSED"]));

    return (
      <div className="rounded border border-solid border-neutral-border w-full z-[1]">
        <EscalationFollowUp
          flat={true}
          user={user}
          employee={employee}
          escalationId={escalationId}
          onSubmit={handleEscalationFollowUpSubmit}
          isLogNotes={shouldLogNotes}
        />
      </div>
    );
  };

  const formatEmployeeGlanceData = (apiData = []) => {
    return apiData.map((item) => ({
      triggerDay: item.trigger_day,
      completed: item.completed,
      responseRate: item["Response Rate"],
      sentimentScore: item["Sentiment Score"],
      postTermination: item.post_termination,
      overallSentiment: item["Overall Sentiment"],
    }));
  };

  return (
    <>
      <div className="w-full h-full flex-col gap-8 p-4 md:p-12 items-start flex bg-default-background container max-w-none overflow-auto">
        <div className="grow shrink-0 basis-0 h-full w-full rounded flex-col gap-6 items-start flex bg-default-background">
          {isFetchingEmployee ? (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Skeleton variant="rounded" width="100%" height="80px" />
            </Box>
          ) : (
            <div className="w-full rounded gap-4 pt-4 pr-4 pb-4 pl-4 items-center flex bg-default-background">
              <Avatar
                className="flex-none w-16 h-16"
                variant={
                  SENTIMENT[employee?.survey?.overall_sentiment]
                    ?.avatarVariant ?? SENTIMENT["No Data"].avatarVariant
                }
              >
                <span className="text-[20px]">
                  {getAcronym(
                    getFullName(
                      employee?.profile?.first_name,
                      employee?.profile?.last_name
                    )
                  )}
                </span>
              </Avatar>
              <div className="grow shrink-0 basis-0 w-full flex-col items-start flex">
                <span className="text-default-font text-header font-header">
                  {getFullName(
                    employee?.profile?.first_name,
                    employee?.profile?.last_name
                  )}
                </span>
                <span className="text-subtext-color text-body font-body">
                  {isStringEmpty(employee?.profile?.job_title)
                    ? "-"
                    : employee?.profile?.job_title}
                  ,{" "}
                  {isStringEmpty(employee?.profile?.office_location)
                    ? "-"
                    : employee?.profile?.office_location}
                  ,{" "}
                  {isStringEmpty(employee?.profile?.region)
                    ? "-"
                    : employee?.profile?.region}
                </span>
              </div>
              <div className="flex gap-1 justify-between items-center">
                {typeof employee?.profile?.is_active === "boolean" &&
                  !employee?.profile?.is_active && (
                    <CustomBadge variant="error" icon="FeatherUserX">
                      Terminated
                    </CustomBadge>
                  )}
                {employee?.survey?.escalation_status === "AT_RISK" && (
                  <CustomBadge variant="error" icon="FeatherAlertTriangle">
                    At-risk
                  </CustomBadge>
                )}
              </div>
            </div>
          )}

          {renderLogNotes()}

          <div className="flex w-full flex-wrap items-start gap-6 pb-6">
            <div className="flex w-full sm:min-w-[335px] lg:max-w-[448px] grow shrink-0 basis-0 flex-col items-start gap-6">
              <div className="w-full rounded border border-solid border-neutral-border flex-col gap-4 pt-4 pr-4 pb-4 pl-4 items-start flex bg-default-background">
                <span className="text-default-font text-subheader font-subheader">
                  Most Recent Sentiment Score
                </span>
                <>
                  {isFetchingEmployee ? (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Skeleton variant="rounded" width="100%" height="150px" />
                    </Box>
                  ) : (
                    <>
                      <div className="w-full min-w-[320px] gap-4 pt-3 pr-3 pb-3 pl-3 items-center flex">
                        <SentimentSummary
                          score={
                            isNumber(employee?.survey?.["sentiment_score"])
                              ? employee?.survey?.["sentiment_score"]
                              : undefined
                          }
                          variant={
                            SENTIMENT[employee?.survey?.["overall_sentiment"]]
                              ?.value ?? SENTIMENT["No Data"]?.value
                          }
                        />
                      </div>
                      <div className="w-full gap-4 pt-3 pr-3 pb-3 pl-3 items-start flex">
                        <SubframeCore.Icon
                          className="text-neutral-400 text-section-header font-section-header"
                          name="FeatherMessageSquare"
                        />
                        <div className="grow shrink-0 basis-0 w-full flex-col gap-2 items-start flex">
                          <span className="w-full text-default-font text-body-bold font-body-bold">
                            Sentiment by topic
                          </span>
                          <div className="grow shrink-0 basis-0 h-full w-full gap-2 flex-wrap items-start flex">
                            {!isObjectEmpty(employee?.sentiment_categories)
                              ? Object.keys(
                                  employee?.sentiment_categories
                                )?.map((category) => (
                                  <SentimentBadge
                                    variant={
                                      SENTIMENT[
                                        employee?.sentiment_categories[category]
                                          ?.overall_sentiment
                                      ]?.value ?? SENTIMENT["No Data"]?.value
                                    }
                                    score={category}
                                  />
                                ))
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              </div>
              <RecognitionsAndReferrals
                user={user}
                employee={employee}
                isFetchingUser={isLoadingUser}
                isFetchingEmployee={isFetchingEmployee}
                handleRecognitionPostSubmit={handleFetchEmployeeHistory}
              />
              <div className="w-full rounded border border-solid border-neutral-border flex-col gap-4 pt-4 pr-4 pb-4 pl-4 items-start flex bg-default-background">
                <span className="text-default-font text-subheader font-subheader">
                  Details
                </span>
                {isFetchingEmployee ? (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Skeleton variant="rounded" width="100%" height="450px" />
                  </Box>
                ) : (
                  <div className="w-full flex-col gap-4 items-start flex">
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherPhone"
                      label="Phone"
                      value={
                        isStringEmpty(employee?.profile?.phone_number)
                          ? "-"
                          : formatPhoneNumber(employee?.profile?.phone_number)
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherMail"
                      label="Email"
                      value={
                        isStringEmpty(employee?.profile?.email)
                          ? "-"
                          : employee?.profile?.email
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherUser"
                      label="Supervisor"
                      value={
                        isStringEmpty(employee?.profile?.supervisor)
                          ? "-"
                          : employee?.profile?.supervisor
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherMapPin"
                      label="Location"
                      value={
                        isStringEmpty(employee?.profile?.office_location)
                          ? "-"
                          : employee?.profile?.office_location
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherGlobe"
                      label="Region"
                      value={
                        isStringEmpty(employee?.profile?.region)
                          ? "-"
                          : employee?.profile?.region
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherCalendar"
                      label="Tenure"
                      value={
                        isStringEmpty(employee?.profile?.tenure)
                          ? "-"
                          : employee?.profile?.tenure
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherBuilding"
                      label="Hire Date"
                      value={
                        isStringEmpty(employee?.profile?.hired_at)
                          ? "-"
                          : formatDate(employee?.profile?.hired_at)
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherBadgeCheck"
                      label="Employment Status"
                      value={
                        typeof employee?.profile?.is_active === "boolean"
                          ? employee?.profile?.is_active
                            ? "Active"
                            : "Terminated"
                          : "-"
                      }
                    />
                    <ProfileDetailItem
                      variant="label"
                      icon="FeatherBriefcase"
                      label="Category"
                      value={
                        isStringEmpty(employee?.profile?.category)
                          ? "-"
                          : employee?.profile?.category
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-6 rounded self-stretch">
              <div className="w-full rounded border border-solid border-neutral-border flex-col gap-4 pt-4 pr-4 pb-4 pl-4 items-start flex bg-default-background">
                <EmployeeGlanceChart
                  data={formatEmployeeGlanceData(employee?.survey_timeline)}
                  isLoading={isFetchingEmployee}
                />
              </div>

              <div className="w-full rounded border border-solid border-neutral-border flex-col gap-4 pt-4 pr-4 pb-4 pl-4 items-start flex bg-default-background">
                <History
                  isLoading={isFetchingEmployeeHistory}
                  employeeHistory={employeeHistory}
                  employeeId={employeeId}
                  handleNoteSummaryToggle={handleFetchEmployeeHistory}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderErrorSnackbar()}
    </>
  );
}

export default EmployeeProfile;
