/*
 * Documentation:
 * Settings page — https://app.subframe.com/library?component=Settings+page_3983ed4a-8495-4470-af14-3897dd72ff76
 * Text Input — https://app.subframe.com/library?component=Text+Input_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Switch — https://app.subframe.com/library?component=Switch_7a464794-9ea9-4040-b1de-5bfb2ce599d9
 * InfoIconWithImage — https://app.subframe.com/library?component=InfoIconWithImage_9ba45521-c87f-46f9-b7d1-a2ba7b572c3f
 * Table — https://app.subframe.com/library?component=Table_142dfde7-d0cc-48a1-a04c-a08ab2252633
 */

import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { PageHeader, TextField } from "subframe";
import * as SubframeCore from "@subframe/core";
import { Switch } from "subframe/components/Switch";
import { Button } from "subframe/components/Button";
import { Slider } from "subframe/components/Slider";

import { isNumber } from "utils/number";
import { setProperty } from "utils/object";
import { isEmpty as isStringEmpty } from "utils/string";

import {
  saveVCard,
  updateAccountName,
  fetchAgencySettings,
  updateAgencySettings,
} from "modules/Retention/services/retention";

import { mixpanelTrackEvent } from "services/mixpanel";
import { EVENT_TYPES } from "constants/mixpanel";
import { AGENCY_ROLE, ERROR_CODE } from "constants/constants";
import { get as getUserRole } from "utils/localStorage";

function SettingsPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [newAccountName, setNewAccountName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [
    hasTriedAccountNameChangeFormSubmission,
    setHasTriedAccountNameChangeFormSubmission,
  ] = useState(false);
  const [isUpdatingAgencySettings, setIsUpdatingAgencySettings] =
    useState(false);
  const [vCardEmail, setVCardEmail] = useState("");
  const [vCardAddress, setVCardAddress] = useState("");
  const [vCardLastName, setVCardLastName] = useState("");
  const [vCardFirstName, setVCardFirstName] = useState("");
  const [agencySettings, setAgencySettings] = useState({
    enable_survey_feature_status: undefined,
    escalation_low_sentiment_threshold: undefined,
    escalation_overdue_classification_hours: undefined,
  });
  const [isSavingVCard, setIsSavingVCard] = useState(false);
  const [vCardPhoneNumber, setVCardPhoneNumber] = useState("");
  const [isFetchingAgencySettings, setIsFetchingAgencySettings] =
    useState(false);
  const [hasTriedVCardFormSubmission, setHasTriedVCardFormSubmission] =
    useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isUpdatingAccountName, setIsUpdatingAccountName] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [confirmedNewAccountName, setConfirmedNewAccountName] = useState("");

  useEffect(() => {
    fetchAndSetAgencySettings();
    mixpanelTrackEvent(EVENT_TYPES.SETTINGS_PAGE_NAVIGATION);
  }, []);

  const fetchAndSetAgencySettings = async () => {
    setIsFetchingAgencySettings(true);
    const response = await fetchAndSetAgencySettingsAsync();

    setIsFetchingAgencySettings(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while fetching Agency Settings: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);
      setAgencySettings({
        enable_survey_feature_status: undefined,
        escalation_low_sentiment_threshold: undefined,
        escalation_overdue_classification_hours: undefined,
      });
    }

    setAgencySettings(response?.result?.settings);
  };

  const fetchAndSetAgencySettingsAsync = async () => {
    try {
      return await fetchAgencySettings();
    } catch (error) {
      if (error?.code === ERROR_CODE.CANCELED) {
        navigate("/");
      }

      return { hasError: true, error };
    }
  };

  const isChangeAccountNameFormValid =
    !isStringEmpty(newAccountName) &&
    !isStringEmpty(confirmedNewAccountName) &&
    newAccountName === confirmedNewAccountName;

  const isSaveVCardFormValid =
    !isStringEmpty(vCardFirstName) &&
    !isStringEmpty(vCardLastName) &&
    !isStringEmpty(vCardEmail) &&
    !isStringEmpty(vCardAddress) &&
    !isStringEmpty(vCardPhoneNumber);

  const updateAccountNameAsync = async (payload = {}) => {
    try {
      return await updateAccountName(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const resetChangeAccountNameForm = () => {
    setNewAccountName("");
    setConfirmedNewAccountName("");
    setHasTriedAccountNameChangeFormSubmission(false);
  };

  const handleAccountNameChangeClick = async () => {
    setHasTriedAccountNameChangeFormSubmission(true);

    if (!isChangeAccountNameFormValid) {
      return;
    }

    setIsUpdatingAccountName(true);
    const response = await updateAccountNameAsync({
      name: confirmedNewAccountName,
    });

    setIsUpdatingAccountName(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while updating account name: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    resetChangeAccountNameForm();
    setSuccessMessage("Successfully updated the account name.");
    setIsSuccessMessageShown(true);
  };

  const getConfirmNewAccountNameError = () => {
    if (!hasTriedAccountNameChangeFormSubmission) {
      return "";
    }

    if (isStringEmpty(confirmedNewAccountName)) {
      return "New name is required";
    }

    if (newAccountName !== confirmedNewAccountName) {
      return "New name doesn't match";
    }

    return "";
  };

  const saveVCardAsync = async (payload = {}) => {
    try {
      return await saveVCard(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const resetSaveVCardForm = () => {
    setVCardFirstName("");
    setVCardLastName("");
    setVCardEmail("");
    setVCardAddress("");
    setVCardPhoneNumber("");
    setHasTriedVCardFormSubmission(false);
  };

  const handleVCardSaveClick = async () => {
    setHasTriedVCardFormSubmission(true);

    if (!isSaveVCardFormValid) {
      return;
    }

    setIsSavingVCard(true);
    const response = await saveVCardAsync({
      first_name: vCardFirstName,
      last_name: vCardLastName,
      email: vCardEmail,
      full_address: vCardAddress,
      phone_number: vCardPhoneNumber,
    });

    setIsSavingVCard(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while saving Virtual Contact Card info: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    resetSaveVCardForm();
    setSuccessMessage("Successfully save the Virtual Contact card.");
    setIsSuccessMessageShown(true);
  };

  /**
   * Renders the error snackbar.
   *
   * @returns {Component}
   */
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Renders the success snackbar.
   *
   * @returns {Component}
   */
  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  /**
   * Handles the change in the value of the Agency Settings.
   */
  const handleAgencySettingsUpdate = (key, value) => {
    setAgencySettings(setProperty(agencySettings, key, value));
  };

  const handleUpdateAgencySettings = async () => {
    setIsUpdatingAgencySettings(true);
    const response = await updateAgencySettingsAsync({
      enable_survey_feature_status:
        agencySettings?.enable_survey_feature_status,
      escalation_low_sentiment_threshold:
        agencySettings?.escalation_low_sentiment_threshold,
      escalation_overdue_classification_hours:
        agencySettings?.escalation_overdue_classification_hours,
    });

    setIsUpdatingAgencySettings(false);

    if (response?.hasError) {
      setErrorMessage(
        `Error while updating agency settings: ${response?.error?.message}`
      );
      setIsErrorMessageShown(true);

      return;
    }

    setSuccessMessage("Successfully updated agency settings.");
    setIsSuccessMessageShown(true);
  };

  const updateAgencySettingsAsync = async (payload = {}) => {
    try {
      return await updateAgencySettings(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const isAgency = () => getUserRole("userRole") === AGENCY_ROLE.agency.value;

  return (
    <>
      <div className="grow shrink-0 basis-0 w-full flex-col gap-6 p-4 md:p-12 items-center flex bg-default-background container max-w-none">
        <div className="w-full md:w-144 flex-col gap-9 items-start flex">
          <div className="w-full flex-col gap-3 items-start flex">
            <PageHeader>
              <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
                <SubframeCore.Icon
                  className="text-neutral-400 text-header font-header"
                  name="FeatherSettings"
                />
                <span className="grow shrink-0 basis-0 w-full text-default-font text-section-header font-section-header line-clamp-1">
                  Settings
                </span>
              </div>
            </PageHeader>
            <span className="w-full text-subtext-color text-body font-body">
              Update your information here
            </span>
          </div>

          <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />

          <div className="w-full flex-col gap-4 items-start flex">
            <span className="text-default-font text-subheader font-subheader">
              Change organization name
            </span>

            <TextField
              className="h-full w-full grow shrink-0 basis-0"
              label="New Name"
              value={newAccountName}
              onChange={(event) => setNewAccountName(event?.target?.value)}
              helpText={
                hasTriedAccountNameChangeFormSubmission &&
                isStringEmpty(newAccountName)
                  ? "New name is required"
                  : ""
              }
              error={
                hasTriedAccountNameChangeFormSubmission &&
                isStringEmpty(newAccountName)
              }
            >
              <TextField.Input placeholder="Enter new name" />
            </TextField>

            <TextField
              className="h-full w-full grow shrink-0 basis-0"
              label="Confirm New Name"
              value={confirmedNewAccountName}
              onChange={(event) =>
                setConfirmedNewAccountName(event?.target?.value)
              }
              helpText={getConfirmNewAccountNameError()}
              error={!isStringEmpty(getConfirmNewAccountNameError())}
            >
              <TextField.Input placeholder="Confirm new name" />
            </TextField>

            <div className="w-full flex-col gap-6 items-end justify-center flex">
              <Button
                variant="brand-primary"
                onClick={handleAccountNameChangeClick}
                disabled={isUpdatingAccountName}
                loading={isUpdatingAccountName}
              >
                Change account name
              </Button>
            </div>
          </div>

          {isAgency() && (
            <>
              <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />
              <div className="w-full flex-col gap-6 items-start flex">
                <span className="text-default-font text-subheader font-subheader">
                  Enable / disable features
                </span>
                {isFetchingAgencySettings ? (
                  <>
                    <div className="gap-2 w-full flex-col flex">
                      <Skeleton variant="rounded" width="100%" height="40px" />
                      <Skeleton variant="rounded" width="100%" height="40px" />
                      <Skeleton variant="rounded" width="100%" height="40px" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full gap-4 items-center flex">
                      <div className="grow shrink-0 basis-0 w-full flex-col items-start flex">
                        <span className="text-default-font text-body-bold font-body-bold">
                          SMS surveys
                        </span>
                        <span className="text-subtext-color text-body font-body">
                          Hear from your employees via easy to respond SMS
                          surveys
                        </span>
                      </div>
                      <Switch
                        checked={agencySettings?.enable_survey_feature_status}
                        onCheckedChange={(value) =>
                          handleAgencySettingsUpdate(
                            "enable_survey_feature_status",
                            value
                          )
                        }
                      >
                        <Switch.Thumb />
                      </Switch>
                    </div>
                    <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />
                    {isNumber(
                      agencySettings?.escalation_low_sentiment_threshold
                    ) && (
                      <div className="w-full flex-col gap-2 items-start flex">
                        <div className="w-full flex-col items-start flex">
                          <span className="text-default-font text-body-bold font-body-bold">
                            Escalation threshold:{" "}
                            {agencySettings?.escalation_low_sentiment_threshold}
                          </span>
                          <span className="text-default-font text-body font-body">
                            Every score under this value will trigger an
                            escalation.
                          </span>
                        </div>
                        <div className="w-full gap-2 items-center flex">
                          <div className="grow shrink-0 basis-0 w-full flex-col gap-2 pt-1 pr-1 pb-1 pl-1 items-start flex">
                            <div className="w-full gap-2 items-center flex">
                              <div className="text-default-font text-body font-body w-[35px]">
                                0
                              </div>
                              <Slider
                                min={0}
                                max={100}
                                value={[
                                  agencySettings?.escalation_low_sentiment_threshold,
                                ]}
                                defaultValue={[
                                  agencySettings?.escalation_low_sentiment_threshold,
                                ]}
                                onValueChange={(value) =>
                                  handleAgencySettingsUpdate(
                                    "escalation_low_sentiment_threshold",
                                    value?.[0]
                                  )
                                }
                              />
                              <div className="text-default-font text-body font-body w-[35px]">
                                100
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isNumber(
                      agencySettings?.escalation_overdue_classification_hours
                    ) && (
                      <div className="w-full flex-col gap-2 items-start flex">
                        <div className="w-full flex-col items-start flex">
                          <span className="text-default-font text-body-bold font-body-bold">
                            Follow-up threshold:{" "}
                            {
                              agencySettings?.escalation_overdue_classification_hours
                            }{" "}
                            hours
                          </span>
                          <span className="text-default-font text-body font-body">
                            Every escalation over this value will be marked as
                            overdue in the inbox
                          </span>
                        </div>
                        <div className="w-full gap-2 items-center flex">
                          <div className="grow shrink-0 basis-0 w-full flex-col gap-2 pt-1 pr-1 pb-1 pl-1 items-start flex">
                            <div className="w-full gap-2 items-center flex">
                              <div className="text-default-font text-body font-body w-[35px]">
                                24
                              </div>
                              <Slider
                                min={24}
                                max={200}
                                value={[
                                  agencySettings?.escalation_overdue_classification_hours,
                                ]}
                                defaultValue={[
                                  agencySettings?.escalation_overdue_classification_hours,
                                ]}
                                onValueChange={(value) =>
                                  handleAgencySettingsUpdate(
                                    "escalation_overdue_classification_hours",
                                    value?.[0]
                                  )
                                }
                              />
                              <div className="text-default-font text-body font-body w-[35px]">
                                200
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="w-full flex-col gap-6 items-end justify-center flex">
                      <Button
                        variant="Brand Primary"
                        onClick={handleUpdateAgencySettings}
                        disabled={isUpdatingAgencySettings}
                        loading={isUpdatingAgencySettings}
                      >
                        Save changes
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />

          <div className="w-full flex-col gap-6 items-start flex">
            <span className="text-default-font text-subheader font-subheader">
              Virtual contact card
            </span>
            <div className="w-full gap-2 items-center flex">
              <TextField
                value={vCardFirstName}
                icon="FeatherUser"
                className="grow shrink-0 basis-0 w-full h-auto"
                label="First name (on the card)"
                helpText={
                  hasTriedVCardFormSubmission && isStringEmpty(vCardFirstName)
                    ? "First name is required"
                    : ""
                }
                error={
                  hasTriedVCardFormSubmission && isStringEmpty(vCardFirstName)
                }
                onChange={(event) => setVCardFirstName(event?.target?.value)}
              >
                <TextField.Input placeholder="" />
              </TextField>

              <TextField
                className="grow shrink-0 basis-0 w-full h-auto"
                label="Last name (on the card)"
                value={vCardLastName}
                icon="FeatherUser"
                errorText={
                  hasTriedVCardFormSubmission && isStringEmpty(vCardLastName)
                    ? "Last name is required"
                    : ""
                }
                error={
                  hasTriedVCardFormSubmission && isStringEmpty(vCardLastName)
                }
                onChange={(event) => setVCardLastName(event?.target?.value)}
              >
                <TextField.Input placeholder="" />
              </TextField>
            </div>
            <div className="w-full gap-4 items-center flex">
              <TextField
                className="h-auto w-full flex-none"
                label="Email"
                icon="FeatherMail"
                value={vCardEmail}
                errorText={
                  hasTriedVCardFormSubmission && isStringEmpty(vCardEmail)
                    ? "Email is required"
                    : ""
                }
                error={hasTriedVCardFormSubmission && isStringEmpty(vCardEmail)}
                onChange={(event) => setVCardEmail(event?.target?.value)}
              >
                <TextField.Input placeholder="" />
              </TextField>
            </div>
            <div className="w-full gap-4 items-center flex">
              <TextField
                className="h-auto w-full flex-none"
                label="Address"
                icon="FeatherHome"
                value={vCardAddress}
                errorText={
                  hasTriedVCardFormSubmission && isStringEmpty(vCardAddress)
                    ? "Address is required"
                    : ""
                }
                error={
                  hasTriedVCardFormSubmission && isStringEmpty(vCardAddress)
                }
                onChange={(event) => setVCardAddress(event?.target?.value)}
              >
                <TextField.Input placeholder="" />
              </TextField>
            </div>

            <TextField
              className="h-auto w-full flex-none"
              label="Phone number"
              icon="FeatherPhone"
              value={vCardPhoneNumber}
              errorText={
                hasTriedVCardFormSubmission && isStringEmpty(vCardPhoneNumber)
                  ? "Phone number is required"
                  : ""
              }
              error={
                hasTriedVCardFormSubmission && isStringEmpty(vCardPhoneNumber)
              }
              onChange={(event) => setVCardPhoneNumber(event?.target?.value)}
            >
              <TextField.Input placeholder="" />
            </TextField>
            <div className="w-full flex-col gap-6 items-end justify-center flex">
              <Button
                variant="Brand Primary"
                onClick={handleVCardSaveClick}
                disabled={isSavingVCard}
                loading={isSavingVCard}
              >
                Save
              </Button>
            </div>
          </div>

          <div className="flex-none h-px w-full flex-col gap-2 items-center flex bg-neutral-300" />
        </div>
      </div>
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
}

export default SettingsPage;
