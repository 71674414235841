import { get } from "lodash";
import HeadcountForecastGridView from "../HeadcountForecastGridView";
import * as SubframeCore from "@subframe/core";

import "./HeadcountForecastGrid.css";

export default function HeadcountForecastGrid({
  rowCount,
  rows = [],
  paginationModel,
  isLoading = false,
  setPaginationModel,
  onJobClick,
  fetchAllData,
}) {
  const formatDecimal = (value) => {
    return typeof value === "number" ? Number(value).toFixed(1) : value;
  };

  const columns = [
    {
      field: "job_title",
      headerName: "Job",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherBriefcase" size={16} className="text-gray-500" />
          <span>Job</span>
        </div>
      ),
      cellClassName: "cursor-pointer hover:text-blue-600",
      renderCell: (params) => (
        <div
          className="w-full h-full flex items-center"
          onClick={() => onJobClick(params.value, params.row.job_id)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "forecasted_headcount",
      headerName: "Forecasted Headcount",
      flex: 1,
      minWidth: 160,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherUsers" size={16} className="text-gray-500" />
          <span>Forecasted Headcount</span>
        </div>
      ),
      valueFormatter: (params) => formatDecimal(params.value),
    },
    {
      field: "current_employees",
      headerName: "Current Emp. on Payroll",
      flex: 1,
      minWidth: 140,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherUserCheck" size={16} className="text-gray-500" />
          <span>Current Emp. on Payroll</span>
        </div>
      ),
    },
    {
      field: "employees_at_risk",
      headerName: "Employees at Risk",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherUserMinus" size={16} className="text-gray-500" />
          <span>Employees at Risk</span>
        </div>
      ),
    },
    {
      field: "predicted_employees",
      headerName: "Predicted Emp. on Payroll",
      flex: 1,
      minWidth: 140,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherActivity" size={16} className="text-gray-500" />
          <span>Predicted Emp. on Payroll</span>
        </div>
      ),
    },
  ];

  const arrangeData = (rows = [], columns = []) => {
    return rows.map((row) => ({
      id: row?.job_id,
      ...row,
      ...Object.fromEntries(
        columns.map((column) => [column.field, get(row, column.field)])
      ),
    }));
  };

  return (
    <HeadcountForecastGridView
      columns={columns}
      rowCount={rowCount}
      isLoading={isLoading}
      rows={arrangeData(rows, columns)}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      fetchAllData={fetchAllData}
    />
  );
}
