import StyledDataGrid from "../../components/shared/StyledDataGrid";
import GridToolbar from '../shared/GridToolbar/GridToolbar';

export default function HeadcountForecastGridView({
  rows,
  columns,
  rowCount,
  isLoading,
  paginationModel,
  setPaginationModel,
  fetchAllData,
  ...props
}) {
  const handlePageChange = (newPage) => {
    setPaginationModel((old) => ({ ...old, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel((old) => ({ ...old, pageSize: newPageSize }));
  };

  const renderCell = (content, className = "") => {
    return (
      <div className={`custom-datagrid-cell-wrapper ${className}`}>
        {content}
      </div>
    );
  };

  const renderRiskCell = (value) => {
    return renderCell(value, "text-error-600");
  };

  const enhancedColumns = [
    ...columns.map((column) => ({
      ...column,
      headerClassName: "super-app-theme--header",
      renderCell: column.renderCell || ((params) => {
        if (column.field === "employees_at_risk") {
          return renderRiskCell(params.value);
        }
        return renderCell(params.value);
      }),
    })),
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 50,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <GridToolbar 
          rows={rowsWithTotals} 
          title="Forecast_Details" 
          columns={enhancedColumns}
          fetchAllData={fetchAllData}
        />
      ),
      renderCell: () => null,
    }
  ];

  // Calculate totals
  const totals = rows.length
    ? rows.reduce((acc, row) => ({
        forecasted_headcount: (acc.forecasted_headcount || 0) + (Number(row?.forecasted_headcount) || 0),
        current_employees: (acc.current_employees || 0) + (Number(row?.current_employees) || 0),
        employees_at_risk: (acc.employees_at_risk || 0) + (Number(row?.employees_at_risk) || 0),
        predicted_employees: (acc.predicted_employees || 0) + (Number(row?.predicted_employees) || 0),
      }), {})
    : {
        forecasted_headcount: 0,
        current_employees: 0,
        employees_at_risk: 0,
        predicted_employees: 0,
      };

  const rowsWithTotals = rows.length
    ? [
        ...rows,
        {
          id: 'total',
          job_title: 'Total',
          forecasted_headcount: Number((totals?.forecasted_headcount || 0).toFixed(1)),
          current_employees: Number((totals?.current_employees || 0).toFixed(1)),
          employees_at_risk: Number((totals?.employees_at_risk || 0).toFixed(1)),
          predicted_employees: Number((totals?.predicted_employees || 0).toFixed(1)),
        }
      ]
    : rows;

  return (
    <StyledDataGrid
      rows={rowsWithTotals}
      columns={enhancedColumns}
      rowCount={rowCount}
      loading={isLoading}
      paginationModel={paginationModel}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      disableColumnMenu
      disableColumnSelector
      getRowClassName={(params) => 
        params.id === "total" ? "pinned font-bold" : ""
      }
      components={{
        Toolbar: () => null
      }}
      {...props}
    />
  );
}
