import React from "react";
import { Modal as MuiModal } from "@mui/material";
import { Button } from "subframe/components/Button";

export default function DeleteConfirmationModal({
  open,
  onClose,
  onConfirm,
  title,
  message,
  isDeleting,
}) {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-title"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl p-6 w-[400px]">
        <h2 id="delete-confirmation-title" className="text-lg font-semibold mb-4">
          {title}
        </h2>
        <p className="text-sm text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end gap-2">
          <Button
            variant="Brand Secondary"
            onClick={onClose}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            variant="Brand Primary"
            onClick={onConfirm}
            loading={isDeleting}
            className="!bg-red-600 hover:!bg-red-700"
          >
            Delete
          </Button>
        </div>
      </div>
    </MuiModal>
  );
} 