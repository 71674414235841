import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { isNumber } from "utils/number";

const getDynamicColor = (value, thresholds, type) => {
  if (!isNumber(value)) {
    return thresholds.default;
  }

  const colors = thresholds[type] || thresholds.default;

  for (const { max, color } of colors) {
    if (value <= max) {
      return color;
    }
  }

  return thresholds.default;
};

const MetricChart = ({
  metricName = "Metric",
  branchValue,
  peerValue,
  companyValue,
  thresholds,
  formatValue = (value) => `${value.toFixed(1)}%`,
}) => {
  const clampedBranchValue = Math.min(branchValue, 100);
  const clampedPeerValue = isNumber(peerValue) ? Math.min(peerValue, 100) : 0;
  const clampedCompanyValue = isNumber(companyValue)
    ? Math.min(companyValue, 100)
    : 0;

  const data = [
    {
      name: "Branch",
      value: clampedBranchValue,
    },
  ];

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex w-full justify-between items-center">
        <span className="text-body font-body text-default-font">
          {metricName}
        </span>
        <span
          className="text-body-bold font-body-bold text-default-font"
          style={{
            color: getDynamicColor(branchValue, thresholds, "textColors"),
          }}
        >
          {isNumber(branchValue) ? formatValue(branchValue) : "-"}
        </span>
      </div>
      <ResponsiveContainer width="100%" height={75}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
        >
          <XAxis
            type="number"
            domain={[0, 100]}
            tick={false}
            tickFormatter={formatValue}
            tickLine={false}
            axisLine={false}
            tickMargin={0}
          />
          <YAxis type="category" dataKey="name" hide />

          <Bar
            dataKey="value"
            background={{ fill: "#e5e5e5", radius: [10, 10, 10, 10] }}
            barSize={10}
            radius={[10, 10, 10, 10]}
          >
            <Cell
              fill={getDynamicColor(branchValue, thresholds, "barColors")}
            />
          </Bar>

          <ReferenceLine
            x={clampedPeerValue}
            stroke="#171717"
            strokeWidth={4}
            label={{
              value: `Peer: ${
                isNumber(peerValue) ? formatValue(peerValue) : "-"
              }`,
              position:
                clampedPeerValue <= 10
                  ? "right"
                  : clampedPeerValue >= 90
                  ? "left"
                  : "bottom",
              fill: getDynamicColor(peerValue, thresholds, "textColors"),
              fontSize: 10,
              fontWeight: "bold",
              dy: clampedPeerValue <= 10 || clampedPeerValue >= 90 ? 18 : 0,
            }}
          />

          <ReferenceLine
            x={clampedCompanyValue}
            stroke="#171717"
            strokeWidth={4}
            label={{
              value: `Company: ${
                isNumber(companyValue) ? formatValue(companyValue) : "-"
              }`,
              position:
                clampedCompanyValue <= 10
                  ? "right"
                  : clampedCompanyValue >= 90
                  ? "left"
                  : "top",
              fill: getDynamicColor(companyValue, thresholds, "textColors"),
              fontSize: 10,
              fontWeight: "bold",
              dy:
                clampedCompanyValue <= 10 || clampedCompanyValue >= 90
                  ? -18
                  : 0,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MetricChart;
