import EscalationFollowUp from "modules/Escalation/components/EscalationFollowUp/EscalationFollowUp";
import RecognitionSearchEmployeeV2 from "modules/Recognition/components/RecognitionSearchEmployeeV2";
import RetentionV2 from "modules/Retention/components";
import EmployeeProfileV2 from "modules/Retention/components/EmployeeProfile";
import Inbox from "modules/Retention/components/Inbox";
import Login from "modules/Retention/components/Login";
import Reports from "modules/Retention/components/Reports";
import Roster from "modules/Retention/components/Roster";
import Settings from "modules/Retention/components/Settings";
import { createBrowserRouter, redirect } from "react-router-dom";
import CaregiverProfile from "../components/CaregiverProfile/CaregiverProfile";
import Dashboard from "../components/Dashboard";
import EmployeeProfile from "../components/EmployeeProfile";
import ErrorPage from "../components/ErrorPage";
import Landing from "../components/Landing";
import Retention from "../components/Retention";
import RetentionDashboard from "../components/RetentionDashboard/RetentionDashboard";
import RetentionPersonnel from "../components/RetentionPersonnel";
import ProtectedRoute from "../components/Routes/ProtectedRoutes";
import RecognitionEmployeeProfile from "../modules/Recognition/components/RecognitionEmployeeProfile";
import RecognitionHome from "../modules/Recognition/components/RecognitionHome/RecognitionHome";
import RecognitionLogin from "../modules/Recognition/components/RecognitionLogin";
import RecognitionOTPVerification from "../modules/Recognition/components/RecognitionOTPVerification";
import RecognitionOutlet from "../modules/Recognition/components/RecognitionOutlet";
import RecognitionPrivateComponent from "../modules/Recognition/components/RecognitionPrivateComponent";
import RecognitionSearchEmployee from "../modules/Recognition/components/RecognitionSearchEmployee";
import Referral from "../modules/Referrals/components/Referral/Referral";
import ReferralIntermediateRedirector from "../modules/Referrals/components/ReferralIntermediateRedirector/ReferralIntermediateRedirector";
import ReferralOutlet from "../modules/Referrals/components/ReferralOutlet/ReferralOutlet";
import EmployeeLogin from "../modules/Retention/components/EmployeeLogin";
import EmployeeSignUp from "../modules/Retention/components/EmployeeSignUp";
import PublicRecognitionWrapper from "modules/Recognition/components/PublicRecognitionWrapper";
import AudioSurveyInterface from "modules/Survey/components/AudioSurveyInterface";
import BranchDetails from "modules/Retention/components/BranchDetails";
import EscalationVideoTracker from "modules/Retention/components/EscalationVideoTracker";

export default createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/employee-login",
    element: <EmployeeLogin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/agency-login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/employee-signup",
    element: <EmployeeSignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/caregivers/:id",
    element: (
      <ProtectedRoute>
        <CaregiverProfile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/retention",
    element: (
      <ProtectedRoute>
        <Retention />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        loader: () => redirect("/retention/dashboard"),
      },
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <RetentionDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "personnel",
        element: (
          <ProtectedRoute>
            <RetentionPersonnel />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/employees/:id",
    element: (
      <ProtectedRoute>
        <EmployeeProfile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/escalations/:id/follow-up",
    element: <EscalationFollowUp />,
  },
  {
    path: "/escalations/video/:id",
    element: <EscalationVideoTracker />,
  },
  {
    path: "/recognition",
    element: <RecognitionOutlet />,
    children: [
      {
        index: true,
        loader: () => redirect("/"),
      },
      {
        path: "login",
        element: <RecognitionLogin />,
      },
      {
        path: "public",
        element: <PublicRecognitionWrapper />,
      },
      {
        path: "otp-verification",
        element: <RecognitionOTPVerification />,
      },
      {
        path: "private",
        element: (
          <ProtectedRoute redirectPath="/employee-login">
            <RecognitionSearchEmployeeV2 />
          </ProtectedRoute>
        ),
      },
      // NOTE: Disabling the routes for this first release of Recognition.
      // {
      //   path: "direct-at",
      //   element: (
      //     <ProtectedRoute redirectPath="/recognition/login">
      //       <RecognitionPrivateComponent>
      //         <RecognitionHome />
      //       </RecognitionPrivateComponent>
      //     </ProtectedRoute>
      //   ),
      // },
      // {
      //   path: "search-employees",
      //   element: (
      //     <ProtectedRoute redirectPath="/recognition/login">
      //       <RecognitionPrivateComponent>
      //         <RecognitionSearchEmployee />
      //       </RecognitionPrivateComponent>
      //     </ProtectedRoute>
      //   ),
      // },
      {
        path: "me",
        element: (
          <ProtectedRoute redirectPath="/recognition/login">
            <RecognitionPrivateComponent>
              <RecognitionEmployeeProfile />
            </RecognitionPrivateComponent>
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/referral",
    element: <ReferralOutlet />,
    children: [
      {
        index: true,
        loader: () => redirect("/"),
      },
      {
        path: "agape-care-group",
        element: <Referral />,
      },
      {
        path: "royal-home-care",
        element: <Referral />,
      },
      {
        path: "longevity-care",
        element: <Referral />,
      },
      {
        path: "comfort-keepers",
        element: <Referral />,
      },
      {
        path: "st-croix",
        element: <Referral />,
      },
      {
        path: "helping-hands",
        element: <Referral />,
      },
      {
        path: "intermediate-redirector",
        element: <ReferralIntermediateRedirector />,
      },
    ],
  },
  {
    path: "/retention/V2",
    element: (
      <ProtectedRoute>
        <RetentionV2 />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        loader: () => redirect("/retention/V2/inbox"),
      },
      {
        path: "inbox",
        element: (
          <ProtectedRoute>
            <Inbox />
          </ProtectedRoute>
        ),
      },
      {
        path: "employees/:id",
        element: (
          <ProtectedRoute>
            <EmployeeProfileV2 />
          </ProtectedRoute>
        ),
      },
      {
        path: "roster",
        element: (
          <ProtectedRoute>
            <Roster />
          </ProtectedRoute>
        ),
      },
      {
        path: "reports",
        element: (
          <ProtectedRoute>
            <Reports />
          </ProtectedRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        ),
      },
      {
        path: "branches/:name",
        element: (
          <ProtectedRoute>
            <BranchDetails />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/survey",
    children: [
      {
        index: true,
        loader: () => redirect("/survey/interview"),
      },
      {
        path: "interview",
        element: <AudioSurveyInterface />,
      },
      {
        path: "interview/:id",
        element: <AudioSurveyInterface />,
      },
    ],
  },
]);
