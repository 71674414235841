const endpoints = {
  roster: "/v1/auth/agency/employees/roster",
  newHires: "/v1/auth/agency/employees/new_hires",
  atRisks: "/v1/auth/agency/employees/turnover_risk",
  employeeProfile: "/v1/auth/agency/employee/profile",
  jobStat: "/v1/auth/agency/employee/stats-numbers/jobs",
  findEmployee: "/v1/auth/agency/employees/find_employee",
  employeeSurvey: "/v1/auth/agency/employee/profile/survey",
  tenureStat: "/v1/auth/agency/employee/stats-numbers/tenure",
  questionStat: "/v1/auth/agency/employee/sentiment/category",
  regionStat: "/v1/auth/agency/employee/stats-numbers/region",
  branchStat: "/v1/auth/agency/employee/stats-numbers/location",
  employeeStat: "/v1/auth/agency/employee/stats-numbers/employee",
  locationStat: "/v1/auth/agency/employee/stats-numbers/location",
  jobByTenure: "/v1/auth/agency/employee/stats-numbers/tenure/job",
  supervisorStat: "/v1/auth/agency/employee/stats-numbers/supervisor",
  categoryStat: "/v1/auth/agency/employee/stats-numbers/job/category",
  questionByJob: "/v1/auth/agency/employee/sentiment/category/question",
  regionByTenure: "/v1/auth/agency/employee/stats-numbers/tenure/region",
  jobPanelEmployee: "/v1/auth/agency/employee/stats-numbers/job/employee",
  jobByQuestionCategory: "/v1/auth/agency/employee/sentiment/category/job",
  supervisorByJobCategory:
    "/v1/auth/agency/employee/stats-numbers/job/supervisor",
  tenureJobEmployee:
    "/v1/auth/agency/employee/stats-numbers/tenure/job/employee",
  categoryByTenureJobType:
    "/v1/auth/agency/employee/stats-numbers/tenure/job/category",
  branchByTenureRegion:
    "/v1/auth/agency/employee/stats-numbers/tenure/region/branch",
  employeeByQuestion:
    "/v1/auth/agency/employee/sentiment/category/question/employee",
  tenureRegionEmployee:
    "/v1/auth/agency/employee/stats-numbers/tenure/region/employee",
  labor: "/v1/auth/agency/employee/labor",
  forecasted: "/v1/auth/agency/employee/labor/forecasted",
  officeLocations: "/v1/auth/agency/employees/office-location",
  adc: "/v1/auth/agency/employees/adc",
  newEmployeeWeight: "/v1/auth/agency/settings/new-employee-weight",
};

export default endpoints;
