import React from "react";

const SkeletonLoader = () => (
  <div className="w-full overflow-hidden border border-gray-200 rounded-lg animate-pulse">
    <div className="min-w-full">
      {/* Header */}
      <div className="bg-gray-50">
        <div className="grid grid-cols-3">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="px-6 py-3">
              <div className="h-5 bg-gray-200 rounded w-20 mx-auto"></div>
            </div>
          ))}
        </div>
      </div>
      {/* Body */}
      <div className="bg-white">
        <div className="grid grid-cols-3">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="px-6 py-4">
              <div className="h-4 bg-gray-200 rounded w-12 mx-auto"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default function ADCStatsTable({ adcStats, isLoading }) {
  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <div className="w-full overflow-hidden border border-gray-200 rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-sm font-semibold text-gray-900 text-center"
            >
              Today
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-sm font-semibold text-gray-900 text-center"
            >
              1 Month Ago
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-sm font-semibold text-gray-900 text-center"
            >
              2 Months Ago
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4 text-sm text-gray-900 text-center">
              {adcStats?.current || 0}
            </td>
            <td className="px-6 py-4 text-sm text-gray-900 text-center">
              {adcStats?.last_month || 0}
            </td>
            <td className="px-6 py-4 text-sm text-gray-900 text-center">
              {adcStats?.two_months_ago || 0}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
