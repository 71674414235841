import React, { useState, useEffect } from "react";
import { Modal as MuiModal } from "@mui/material";
import { TextField } from "subframe";
import { Button } from "subframe/components/Button";
import { createServiceLine, updateServiceLine } from "../../services/retention";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

export default function ServiceLineModal({
  open,
  onClose,
  serviceLine = null,
  onSuccess,
}) {
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);

  useEffect(() => {
    if (serviceLine) {
      setName(serviceLine.service_name || "");
    } else {
      setName("");
    }
  }, [serviceLine]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name.trim()) {
      setErrorMessage("Service line name is required");
      setIsErrorMessageShown(true);
      return;
    }

    setIsSubmitting(true);
    try {
      if (serviceLine) {
        await updateServiceLine(serviceLine.id, { name });
        setSuccessMessage("Service line updated successfully");
        setIsSuccessMessageShown(true);
      } else {
        await createServiceLine({ name });
        setSuccessMessage("Service line created successfully");
        setIsSuccessMessageShown(true);
      }
      onSuccess?.();
      onClose();
    } catch (error) {
      console.error("Failed to save service line:", error);
      setErrorMessage(error?.response?.data?.message || "Failed to save service line");
      setIsErrorMessageShown(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  return (
    <>
      <MuiModal
        open={open}
        onClose={onClose}
        aria-labelledby="service-line-modal-title"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl p-6 w-[400px]">
          <h2 id="service-line-modal-title" className="text-lg font-semibold mb-4">
            {`${serviceLine ? "Edit" : "Add"} Service Line`}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <TextField
                label="Name"
                required
              >
                <TextField.Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={serviceLine ? serviceLine.service_name : "Enter service line name"}
                />
              </TextField>
            </div>
            <div className="flex justify-end gap-2">
              <Button
                variant="Brand Secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                variant="Brand Primary"
                loading={isSubmitting}
                type="submit"
              >
                {serviceLine ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </div>
      </MuiModal>
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
} 