import React, { useEffect, useState } from "react";
import { TextField } from "subframe";
import { Button } from "subframe/components/Button";
import {
  fetchJobRatios,
  updateJobRatios,
  createJobRatios,
  fetchServiceLines,
  deleteServiceLine,
  updateJob,
  fetchJobs,
} from "../../services/retention";
import { Skeleton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { PageHeader } from "subframe/components/PageHeader";
import * as SubframeCore from "@subframe/core";
import ServiceLineModal from "../ServiceLineModal/ServiceLineModal";
import DeleteConfirmationModal from "../DeleteConfirmationModal/DeleteConfirmationModal";
import JobEditModal from "../JobEditModal/JobEditModal";
import { get as getUserRole } from "utils/localStorage";
import { EMPLOYEE_ROLES, AGENCY_ROLE } from "constants/constants";

export default function JobRatiosSettings({ onRatiosUpdated }) {
  const [serviceRatios, setServiceRatios] = useState([]);
  const [isEditingService, setIsEditingService] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [isLoadingRatios, setIsLoadingRatios] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [jobTitles, setJobTitles] = useState([]);
  const [isServiceLineModalOpen, setIsServiceLineModalOpen] = useState(false);
  const [selectedServiceLineForEdit, setSelectedServiceLineForEdit] =
    useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedServiceLineForDelete, setSelectedServiceLineForDelete] =
    useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isJobEditModalOpen, setIsJobEditModalOpen] = useState(false);
  const [selectedJobForEdit, setSelectedJobForEdit] = useState(null);
  const [isUpdatingJob, setIsUpdatingJob] = useState(false);
  const [jobsData, setJobsData] = useState([]);

  // Get user role and check if user is admin or agency
  const userRole = getUserRole("userRole");
  const hasEditPermission =
    userRole === EMPLOYEE_ROLES.admin.value ||
    userRole === AGENCY_ROLE.agency.value;

  useEffect(() => {
    handleFetchServiceRatios();
  }, []);

  const handleFetchServiceRatios = async () => {
    setIsLoadingRatios(true);
    try {
      const [serviceResponse, ratiosResponse, jobsResponse] = await Promise.all(
        [fetchServiceLinesAsync(), fetchJobRatiosAsync(), fetchJobs()]
      );

      if (serviceResponse?.hasError) {
        setErrorMessage(
          `Error while fetching service lines: ${serviceResponse?.error?.message}`
        );
        setIsErrorMessageShown(true);
        setServiceRatios([]);
        return;
      }

      if (ratiosResponse?.hasError) {
        setErrorMessage(
          `Error while fetching job ratios: ${ratiosResponse?.error?.message}`
        );
        setIsErrorMessageShown(true);
        return;
      }

      const serviceLines = serviceResponse?.result?.service_lines || [];
      const jobRatios = ratiosResponse?.result?.job_ratios || [];

      // Get unique job titles from the response
      const uniqueJobTitles = [
        ...new Set(jobRatios.map((ratio) => ratio.job_title)),
      ];
      // Filter out test jobs
      const filteredJobTitles = uniqueJobTitles.filter(
        (title) => !title.toLowerCase().includes("test")
      );
      setJobTitles(filteredJobTitles);

      // Store jobs data for editing
      if (jobsResponse?.success) {
        setJobsData(jobsResponse.result.jobs);
      }

      // Transform flat job ratios into service-based structure
      const mappedServiceRatios = serviceLines.map((service) => {
        const serviceRatios = {};
        filteredJobTitles.forEach((jobTitle) => {
          const ratio = jobRatios.find(
            (r) => r.service_line_id === service.id && r.job_title === jobTitle
          );
          serviceRatios[jobTitle] = ratio?.ratio ?? null;
        });

        return {
          service_id: service.id,
          service_name: service.name,
          ratios: serviceRatios,
        };
      });

      setServiceRatios(mappedServiceRatios);
    } catch (error) {
      console.error("Error fetching service ratios:", error);
      setErrorMessage(
        "An unexpected error occurred while fetching service ratios"
      );
      setIsErrorMessageShown(true);
      setServiceRatios([]);
    } finally {
      setIsLoadingRatios(false);
    }
  };

  const fetchServiceLinesAsync = async () => {
    try {
      return await fetchServiceLines();
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const fetchJobRatiosAsync = async () => {
    try {
      return await fetchJobRatios();
    } catch (error) {
      return { hasError: true, error };
    }
  };

  // Add loading state render
  if (isLoadingRatios) {
    return (
      <div className="w-full flex-col gap-6 items-start flex">
        <PageHeader>
          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
            <SubframeCore.Icon
              className="text-subtext-color text-section-header font-section-header"
              name="FeatherSettings"
            />
            <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
              Job Ratios
            </span>
          </div>
        </PageHeader>

        <div className="w-full overflow-x-auto">
          <div className="min-w-full">
            {/* Header Skeleton */}
            <div className="bg-gray-50 h-12 mb-4 flex">
              <Skeleton
                variant="rectangular"
                width={200}
                height={48}
                className="mr-4"
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={48}
                className="mr-4"
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={48}
                className="mr-4"
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={48}
                className="mr-4"
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={48}
                className="mr-4"
              />
              <Skeleton variant="rectangular" width={200} height={48} />
            </div>

            {/* Rows Skeleton */}
            {[1, 2].map((row) => (
              <div key={row} className="flex mb-4">
                <Skeleton
                  variant="rectangular"
                  width={200}
                  height={48}
                  className="mr-4"
                />
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={48}
                  className="mr-4"
                />
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={48}
                  className="mr-4"
                />
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={48}
                  className="mr-4"
                />
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={48}
                  className="mr-4"
                />
                <Skeleton variant="rectangular" width={200} height={48} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Add error and success snackbars
  const renderErrorSnackbar = () => (
    <Snackbar
      open={isErrorMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsErrorMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsErrorMessageShown(false)}
        severity="error"
        sx={{ width: "100%" }}
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  );

  const renderSuccessSnackbar = () => (
    <Snackbar
      open={isSuccessMessageShown}
      autoHideDuration={6000}
      onClose={() => setIsSuccessMessageShown(false)}
    >
      <MuiAlert
        onClose={() => setIsSuccessMessageShown(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        {successMessage}
      </MuiAlert>
    </Snackbar>
  );

  const handleEditServiceRatios = (service) => {
    setSelectedService(service);
    setIsEditingService(true);
  };

  const handleSaveServiceRatios = async () => {
    try {
      // Get existing ratios first to get job_ids
      const existingRatios = await fetchJobRatiosAsync();
      if (existingRatios?.hasError) {
        setErrorMessage(
          `Error while fetching existing ratios: ${existingRatios?.error?.message}`
        );
        setIsErrorMessageShown(true);
        return;
      }

      const existingRatiosForService = existingRatios?.result?.job_ratios || [];

      // Transform the data back to the API format and separate into create/update arrays
      const { toCreate, toUpdate } = Object.entries(selectedService.ratios)
        .filter(([_, ratio]) => ratio !== null) // Filter out null ratios
        .reduce(
          (acc, [jobTitle, ratio]) => {
            // Find the job_id from existing ratios for this job title
            const existingRatio = existingRatiosForService.find(
              (r) => r.job_title === jobTitle
            );

            const ratioData = {
              job_id: existingRatio?.job_id,
              job_title: jobTitle,
              ratio: Number(ratio),
              service_line_id: selectedService.service_id,
            };

            // Check if this specific combination exists
            const existingServiceRatio = existingRatiosForService.find(
              (r) =>
                r.job_title === jobTitle &&
                r.service_line_id === selectedService.service_id
            );

            if (existingServiceRatio) {
              acc.toUpdate.push(ratioData);
            } else {
              acc.toCreate.push(ratioData);
            }

            return acc;
          },
          { toCreate: [], toUpdate: [] }
        );

      let hasError = false;
      let errorMessages = [];

      // Handle creates if any
      if (toCreate.length > 0) {
        const createResponse = await createJobRatiosAsync(toCreate);
        if (createResponse?.hasError) {
          hasError = true;
          errorMessages.push(
            `Error creating ratios: ${createResponse?.error?.message}`
          );
        }
      }

      // Handle updates if any
      if (toUpdate.length > 0) {
        const updateResponse = await updateJobRatiosAsync(toUpdate);
        if (updateResponse?.hasError) {
          hasError = true;
          errorMessages.push(
            `Error updating ratios: ${updateResponse?.error?.message}`
          );
        }
      }

      if (hasError) {
        setErrorMessage(errorMessages.join(". "));
        setIsErrorMessageShown(true);
        return;
      }

      setServiceRatios((prev) =>
        prev.map((service) =>
          service.service_id === selectedService.service_id
            ? selectedService
            : service
        )
      );
      setSuccessMessage("Job ratios saved successfully");
      setIsSuccessMessageShown(true);
      setIsEditingService(false);
      setSelectedService(null);

      // Refresh the data
      handleFetchServiceRatios();

      // Call the callback instead of dispatching an event
      onRatiosUpdated?.();
    } catch (error) {
      setErrorMessage("Failed to save job ratios");
      setIsErrorMessageShown(true);
    }
  };

  const handleRatioChangeByService = (jobTitle, newValue) => {
    setSelectedService((prev) => ({
      ...prev,
      ratios: {
        ...prev.ratios,
        [jobTitle]: newValue === "" ? null : Number(newValue),
      },
    }));
  };

  const createJobRatiosAsync = async (payload) => {
    try {
      return await createJobRatios(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const updateJobRatiosAsync = async (payload) => {
    try {
      return await updateJobRatios(payload);
    } catch (error) {
      return { hasError: true, error };
    }
  };

  const handleEditServiceLine = (service, e) => {
    e.stopPropagation();
    setSelectedServiceLineForEdit({
      id: service.service_id,
      service_name: service.service_name,
    });
    setIsServiceLineModalOpen(true);
  };

  const handleAddServiceLine = (e) => {
    e.stopPropagation();
    setSelectedServiceLineForEdit(null);
    setIsServiceLineModalOpen(true);
  };

  const handleDeleteServiceLine = (service, e) => {
    e.stopPropagation();
    setSelectedServiceLineForDelete({
      id: service.service_id,
      service_name: service.service_name,
    });
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteServiceLine(selectedServiceLineForDelete.id);
      setSuccessMessage("Service line deleted successfully");
      setIsSuccessMessageShown(true);
      handleFetchServiceRatios();
      // Call the parent's callback to refresh service lines in the filter
      onRatiosUpdated?.();
    } catch (error) {
      console.error("Failed to delete service line:", error);
      setErrorMessage(
        error?.response?.data?.message || "Failed to delete service line"
      );
      setIsErrorMessageShown(true);
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
      setSelectedServiceLineForDelete(null);
    }
  };

  const handleEditJob = (jobTitle) => {
    const job = jobsData.find((j) => j.job_title === jobTitle);
    if (job) {
      setSelectedJobForEdit(job);
      setIsJobEditModalOpen(true);
    }
  };

  const handleJobUpdate = async (jobId, data) => {
    setIsUpdatingJob(true);
    try {
      await updateJob(jobId, data);
      setSuccessMessage("Job updated successfully");
      setIsSuccessMessageShown(true);
      handleFetchServiceRatios();
      // Call the parent's callback to refresh service lines in the filter
      onRatiosUpdated?.();
    } catch (error) {
      console.error("Failed to update job:", error);
      setErrorMessage(error?.response?.data?.message || "Failed to update job");
      setIsErrorMessageShown(true);
    } finally {
      setIsUpdatingJob(false);
      setIsJobEditModalOpen(false);
      setSelectedJobForEdit(null);
    }
  };

  return (
    <>
      <div className="w-full flex-col gap-6 items-start flex">
        <PageHeader>
          <div className="grow shrink-0 basis-0 w-full gap-4 items-center flex">
            <SubframeCore.Icon
              className="text-subtext-color text-section-header font-section-header"
              name="FeatherSettings"
            />
            <span className="grow shrink-0 basis-0 w-full text-default-font text-subheader font-subheader line-clamp-1">
              Job Ratios
            </span>
          </div>
        </PageHeader>

        {/* Job Ratios Table */}
        <div className="w-full overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-[200px] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center justify-between">
                    <span>Service</span>
                    {hasEditPermission && (
                      <button
                        onClick={handleAddServiceLine}
                        className="p-1 hover:bg-gray-100 rounded"
                        type="button"
                      >
                        <SubframeCore.Icon
                          name="FeatherPlus"
                          size={14}
                          className="text-gray-500"
                        />
                      </button>
                    )}
                  </div>
                </th>
                {jobTitles.map((jobTitle) => (
                  <th
                    key={jobTitle}
                    className="w-[150px] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider group"
                  >
                    <div className="flex items-center justify-between">
                      <span>{jobTitle}</span>
                      {hasEditPermission && (
                        <button
                          onClick={() => handleEditJob(jobTitle)}
                          className="p-1 hover:bg-gray-100 rounded opacity-0 group-hover:opacity-100 transition-opacity"
                          type="button"
                        >
                          <SubframeCore.Icon
                            name="FeatherEdit2"
                            size={14}
                            className="text-gray-500"
                          />
                        </button>
                      )}
                    </div>
                  </th>
                ))}
                {hasEditPermission && (
                  <th className="w-[200px] px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {serviceRatios.map((service) => (
                <tr key={service.service_id} className="group">
                  <td className="w-[200px] px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex items-center justify-between">
                      <span>{service.service_name}</span>
                      {hasEditPermission && (
                        <div className="flex gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                          <button
                            onClick={(e) => handleEditServiceLine(service, e)}
                            className="p-1 hover:bg-gray-100 rounded"
                            type="button"
                          >
                            <SubframeCore.Icon
                              name="FeatherEdit2"
                              size={14}
                              className="text-gray-500"
                            />
                          </button>
                          <button
                            onClick={(e) => handleDeleteServiceLine(service, e)}
                            className="p-1 hover:bg-gray-100 rounded"
                            type="button"
                          >
                            <SubframeCore.Icon
                              name="FeatherTrash2"
                              size={14}
                              className="text-gray-500"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                  {Object.entries(service.ratios).map(([jobTitle, ratio]) => (
                    <td
                      key={jobTitle}
                      className="w-[150px] px-4 py-3 whitespace-nowrap text-sm text-gray-500"
                    >
                      <div className="h-[36px] flex items-center">
                        {isEditingService &&
                        selectedService?.service_id === service.service_id &&
                        hasEditPermission ? (
                          <TextField
                            className="w-24"
                            value={
                              selectedService.ratios[jobTitle] !== null
                                ? selectedService.ratios[jobTitle]?.toFixed(1)
                                : ""
                            }
                            onChange={(e) =>
                              handleRatioChangeByService(
                                jobTitle,
                                e.target.value
                              )
                            }
                            type="number"
                            step="0.1"
                          >
                            <TextField.Input
                              placeholder={
                                ratio !== null ? ratio?.toFixed(1) : "-"
                              }
                            />
                          </TextField>
                        ) : (
                          <span>
                            {ratio !== null ? ratio?.toFixed(1) : "-"}
                          </span>
                        )}
                      </div>
                    </td>
                  ))}
                  {hasEditPermission && (
                    <td className="w-[200px] px-4 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div className="h-[36px] flex items-center justify-end">
                        {isEditingService &&
                        selectedService?.service_id === service.service_id ? (
                          <div className="flex gap-2">
                            <Button
                              variant="brand-secondary"
                              onClick={() => setIsEditingService(false)}
                              className="whitespace-nowrap"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="brand-primary"
                              onClick={handleSaveServiceRatios}
                              className="whitespace-nowrap"
                            >
                              Save
                            </Button>
                          </div>
                        ) : (
                          <Button
                            variant="brand-secondary"
                            onClick={() => handleEditServiceRatios(service)}
                            className="whitespace-nowrap"
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          {/* Add Service Line Link */}
          {hasEditPermission && (
            <div className="mt-6 pl-4">
              <button
                onClick={handleAddServiceLine}
                className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
                type="button"
              >
                <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center">
                  <SubframeCore.Icon
                    name="FeatherPlus"
                    size={14}
                    className="text-blue-600"
                  />
                </div>
                <span className="text-sm">Add Service Line</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {hasEditPermission && (
        <>
          <ServiceLineModal
            open={isServiceLineModalOpen}
            onClose={() => {
              setIsServiceLineModalOpen(false);
              setSelectedServiceLineForEdit(null);
            }}
            serviceLine={selectedServiceLineForEdit}
            onSuccess={() => {
              handleFetchServiceRatios();
              // Call the parent's callback to refresh service lines in the filter
              onRatiosUpdated?.();
            }}
          />
          <DeleteConfirmationModal
            open={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
              setSelectedServiceLineForDelete(null);
            }}
            onConfirm={handleConfirmDelete}
            title="Delete Service Line"
            message={`Are you sure you want to delete "${selectedServiceLineForDelete?.service_name}"? This action cannot be undone.`}
            isDeleting={isDeleting}
          />
          <JobEditModal
            open={isJobEditModalOpen}
            onClose={() => {
              setIsJobEditModalOpen(false);
              setSelectedJobForEdit(null);
            }}
            jobData={selectedJobForEdit}
            onSave={handleJobUpdate}
            isSaving={isUpdatingJob}
          />
        </>
      )}
      {renderErrorSnackbar()}
      {renderSuccessSnackbar()}
    </>
  );
}
