import { get } from "lodash";
import HeadcountTargetGridView from "../HeadcountTargetGridView";
import { Tooltip } from "@mui/material";
import * as SubframeCore from "@subframe/core";

import "./HeadcountTargetGrid.css";

export default function HeadcountTargetGrid({
  rowCount,
  rows = [],
  paginationModel,
  isLoading = false,
  setPaginationModel,
  onViewDetails = () => {},
  onJobClick,
  fetchAllData,
}) {
  // Helper function to determine cell color based on value
  const getOverUnderColor = (value) => {
    const absValue = Math.abs(value);
    if (absValue <= 1) return "bg-success-600 text-white"; // Dark green for very close to target
    if (absValue <= 2) return "bg-success-50 text-success-600"; // Light green for close to target
    if (absValue <= 3) return "bg-error-50 text-error-600"; // Light red for moderate deviation
    return "bg-error-600 text-white"; // Dark red for significant deviation
  };

  const formatDecimal = (value) => {
    return typeof value === "number" ? Number(value).toFixed(1) : value;
  };

  const columns = [
    {
      field: "job_title",
      headerName: "Job",
      flex: 1,
      minWidth: 200,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherBriefcase" size={16} className="text-gray-500" />
          <span>Job</span>
        </div>
      ),
      cellClassName: "cursor-pointer hover:text-blue-600",
      renderCell: (params) => (
        <div
          className="w-full h-full flex items-center"
          onClick={() => onJobClick(params.value, params.row.job_id)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "target",
      headerName: "Target",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherTarget" size={16} className="text-gray-500" />
          <span>Target</span>
        </div>
      ),
      valueFormatter: (params) => formatDecimal(params.value),
    },
    {
      field: "normalized_headcount",
      headerName: "Normalized Headcount",
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherUsers" size={16} className="text-gray-500" />
          <span>Normalized Headcount</span>
          <div
            onClick={(event) => {
              event.stopPropagation();
              onViewDetails();
            }}
            className="cursor-pointer text-brand-600 hover:text-brand-700 flex items-center"
          >
            <SubframeCore.Icon name="FeatherExternalLink" size={16} />
          </div>
        </div>
      ),
      valueFormatter: (params) => formatDecimal(params.value),
    },
    {
      field: "over_under",
      headerName: "Over / Under",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <div className="flex flex-col -space-y-2 pt-1">
            <SubframeCore.Icon name="FeatherPlus" size={16} className="text-gray-500" />
            <SubframeCore.Icon name="FeatherMinus" size={16} className="text-gray-500" />
          </div>
          <span>Over / Under</span>
        </div>
      ),
      getColorClass: getOverUnderColor,
      valueFormatter: (params) => formatDecimal(params.value),
    },
  ];

  const arrangeData = (rows = [], columns = []) => {
    return rows.map((row) => ({
      id: row?.job_id,
      ...row,
      ...Object.fromEntries(
        columns.map((column) => [column.field, get(row, column.field)])
      ),
    }));
  };

  return (
    <HeadcountTargetGridView
      columns={columns}
      rowCount={rowCount}
      isLoading={isLoading}
      rows={arrangeData(rows, columns)}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      fetchAllData={fetchAllData}
    />
  );
}
