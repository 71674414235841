import React, {useState} from 'react';
import * as SubframeCore from "@subframe/core";
import {Divider, Menu, MenuItem} from "@mui/material";
import {utils, writeFile} from 'xlsx';

export default function GridToolbar({ rows, title, columns, fetchAllData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);

  const calculateTotals = (data) => {
    if (!data.length) return null;
    
    const numericFields = columns
      .filter(col => col.field !== 'job_title' && col.field !== 'actions')
      .map(col => col.field);

    const totals = data.reduce((acc, row) => {
      numericFields.forEach(field => {
        acc[field] = (acc[field] || 0) + (Number(row[field]) || 0);
      });
      return acc;
    }, {});

    // Format numeric values to 1 decimal place
    numericFields.forEach(field => {
      totals[field] = Number(totals[field].toFixed(1));
    });

    return {
      id: 'total',
      job_title: 'Total',
      ...totals
    };
  };

  const getVisibleColumnsData = (data) => {
    const visibleColumns = columns.filter(col => col.field !== 'actions');
    const processedData = data.map(row => {
      const newRow = {};
      visibleColumns.forEach(col => {
        newRow[col.headerName || col.field] = row[col.field];
      });
      return newRow;
    });

    // Calculate and add totals row
    const totalsRow = calculateTotals(data);
    if (totalsRow) {
      const formattedTotalsRow = {};
      visibleColumns.forEach(col => {
        formattedTotalsRow[col.headerName || col.field] = totalsRow[col.field];
      });
      processedData.push(formattedTotalsRow);
    }

    return processedData;
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = async (exportFn) => {
    try {
      setIsLoading(true);
      let dataToExport = rows;
      
      if (fetchAllData) {
        dataToExport = await fetchAllData();
      }

      await exportFn(dataToExport);
    } catch (error) {
      SubframeCore.toast.error('Failed to export data');
      console.error('Export failed:', error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleCopyToClipboard = async () => {
    await handleExport((data) => {
      const processedData = getVisibleColumnsData(data);
      const headers = Object.keys(processedData[0] || {});
      const tsv = [
        headers.join('\t'),
        ...processedData.map(row => headers.map(header => row[header]).join('\t'))
      ].join('\n');

      navigator.clipboard.writeText(tsv);
      SubframeCore.toast.success('Data copied to clipboard');
    });
  };

  const handleExportCSV = async () => {
    await handleExport((data) => {
      const processedData = getVisibleColumnsData(data);
      const headers = Object.keys(processedData[0] || {});
      const csv = [
        headers.join(','),
        ...processedData.map(row => headers.map(header => `"${row[header]}"`).join(','))
      ].join('\n');

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${title || 'export'}.csv`;
      link.click();
    });
  };

  const handleExportXLSX = async () => {
    await handleExport((data) => {
      const processedData = getVisibleColumnsData(data);
      const ws = utils.json_to_sheet(processedData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1');
      writeFile(wb, `${title || 'export'}.xlsx`);
    });
  };

  const handleExportJSON = async () => {
    await handleExport((data) => {
      const processedData = getVisibleColumnsData(data);
      const blob = new Blob([JSON.stringify(processedData, null, 2)], { type: 'application/json' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${title || 'export'}.json`;
      link.click();
    });
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="p-1 hover:bg-gray-100 rounded ml-auto"
        disabled={isLoading}
      >
        {isLoading ? (
          <SubframeCore.Loader size="sm" />
        ) : (
          <SubframeCore.Icon name="FeatherMoreVertical" size={14} />
        )}
      </button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            minWidth: '160px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }
        }}
        MenuListProps={{
          dense: true
        }}
      >
        <MenuItem 
          onClick={handleCopyToClipboard} 
          className="flex items-center gap-2 text-sm py-1"
          disabled={isLoading}
        >
          <SubframeCore.Icon name="FeatherClipboard" size={14} />
          <span>Copy to clipboard</span>
        </MenuItem>

        <Divider style={{ margin: '4px 0' }} />

        <MenuItem 
          onClick={handleExportCSV} 
          className="flex items-center gap-2 text-sm py-1"
          disabled={isLoading}
        >
          <SubframeCore.Icon name="FeatherFileText" size={14} />
          <span>Export as CSV</span>
        </MenuItem>

        <MenuItem 
          onClick={handleExportXLSX} 
          className="flex items-center gap-2 text-sm py-1"
          disabled={isLoading}
        >
          <SubframeCore.Icon name="FeatherFile" size={14} />
          <span>Export as XLSX</span>
        </MenuItem>

        <MenuItem 
          onClick={handleExportJSON} 
          className="flex items-center gap-2 text-sm py-1"
          disabled={isLoading}
        >
          <SubframeCore.Icon name="FeatherCode" size={14} />
          <span>Export as JSON</span>
        </MenuItem>
      </Menu>
    </>
  );
} 