import { get } from "lodash";
import HeadcountGridView from "../HeadcountGridView";
import * as SubframeCore from "@subframe/core";

import "./HeadcountGrid.css";

export default function HeadcountGrid({
  rowCount,
  rows = [],
  paginationModel,
  isLoading = false,
  setPaginationModel,
  onJobClick,
  fetchAllData,
}) {
  const getPercentageColor = (
    percentageValue,
    targetValue,
    normalizedValue
  ) => {
    const overUnder = normalizedValue - targetValue;

    if (overUnder < 0) {
      if (percentageValue > 25) return "bg-success-600 text-white";
      if (percentageValue > 20) return "bg-success-50 text-success-600";
      if (percentageValue > 15) return "bg-success-50 text-success-600";
      if (percentageValue > 10) return "bg-error-50 text-error-600";
      return "bg-error-600 text-white";
    }

    if (overUnder > 0) {
      if (percentageValue > 25) return "bg-error-600 text-white";
      if (percentageValue > 20) return "bg-error-50 text-error-600";
      if (percentageValue > 15) return "bg-error-50 text-error-600";
      if (percentageValue > 10) return "bg-success-50 text-success-600";
      return "bg-success-600 text-white";
    }

    if (percentageValue > 20) return "bg-error-50 text-error-600";
    if (percentageValue > 15) return "bg-success-50 text-success-600";
    return "bg-success-600 text-white";
  };

  const formatDecimal = (value) => {
    return typeof value === "number" ? Number(value).toFixed(1) : value;
  };

  const columns = [
    {
      field: "job_title",
      headerName: "Job",
      flex: 1,
      minWidth: 200,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherBriefcase" size={16} className="text-gray-500" />
          <span>Job</span>
        </div>
      ),
      cellClassName: "cursor-pointer hover:text-blue-600",
      renderCell: (params) => (
        <div
          className="w-full h-full flex items-center"
          onClick={() => onJobClick(params.value, params.row.job_id)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "normalized_headcount",
      headerName: "Normalized Headcount",
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherUsers" size={16} className="text-gray-500" />
          <span>Normalized Headcount</span>
        </div>
      ),
      valueFormatter: (params) => formatDecimal(params.value),
    },
    {
      field: "total_employees",
      headerName: "Employees on Payroll",
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherUserPlus" size={16} className="text-gray-500" />
          <span>Employees on Payroll</span>
        </div>
      ),
    },
    {
      field: "new_employees",
      headerName: "Employees <90 days",
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherUserCheck" size={16} className="text-gray-500" />
          <span>Employees &lt;90 days</span>
        </div>
      ),
    },
    {
      field: "new_hire_percentage",
      headerName: "of New Hires",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div className="flex items-center gap-2">
          <SubframeCore.Icon name="FeatherPercent" size={16} className="text-gray-500" />
          <span>of New Hires</span>
        </div>
      ),
      getColorClass: (value, row) =>
        getPercentageColor(value, row.target, row.normalized_headcount),
    },
  ];

  const arrangeData = (rows = [], columns = []) => {
    return rows.map((row) => ({
      id: row?.job_id,
      ...row,
      ...Object.fromEntries(
        columns.map((column) => [column.field, get(row, column.field)])
      ),
    }));
  };

  return (
    <HeadcountGridView
      columns={columns}
      rowCount={rowCount}
      isLoading={isLoading}
      rows={arrangeData(rows, columns)}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      fetchAllData={fetchAllData}
    />
  );
}
